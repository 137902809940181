import { Component, EventEmitter, Input, Output } from '@angular/core'
import { FormControl } from '@angular/forms'

import { OptionModel } from 'src/app/views/companies/model'

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss']
})
export class SelectComponent {
  @Input() title: string = 'Title'
  @Input() options: { value: string; title: string }[] = []
  @Input() multiple: boolean = false
  @Input() placeholder: string = 'Placeholder'
  @Input() set selectedOptions(options: string[]) {
    this.optionControl.setValue(options)
  }
  @Output() optionsChanged = new EventEmitter()

  optionControl = new FormControl()
  translatedOptions: string[] = []

  handleSelectionChange(value: string[]) {
    this.optionsChanged.emit(value)
  }
}
