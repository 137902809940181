import { Injectable } from '@angular/core'
import { cloneDeep, isEqual, union } from 'lodash'

import { ComponentPieceModel, ConfigurationPieceModel, ZonePieceModel } from 'src/app/components/template-library/models/template-library.model'
import { LANGUAGE_CODE_BY_LANGUAGE_ID, LANGUAGE_CONFIG_TYPES, LANGUAGE_IDS, NODE_LEVELS, NODE_LEVELS_INDEX } from 'src/app/constants/internationalized-constants-en'
import { UndefinedOrNullUUIDValueError } from 'src/app/shared/error'
import { equalsIgnoreCase, getLanguageKey, getLocationWithLimit, isValidArray, toIgnoreCase, trimAll } from 'src/app/utils/utils'
import { ComponentModel, ConfigZoneModel, EvirLanguageDictionary, FormDataModel, InspectionDetailModel, SelectedConfigurationModel, TranslationObject, TranslationObjectValue, TreeFlatNode, TreeNode, ZoneLayoutModel } from '../../models/tree.model'
import { LanguageDictionaryHandlingService } from '../language-dictionary-handling/language-dictionary-handling.service'
import { CopiedInspectionTypeModel } from 'src/app/app.state'

@Injectable({
  providedIn: 'root'
})
export class ImportDataConfigurationService {

  constructor(public langDictionaryService: LanguageDictionaryHandlingService) { }

  copyConfiguration(zoneLayouts: ZoneLayoutModel[], node: TreeFlatNode) {
    const parentsIndex: number[] = node.id.split('/').map(x => Number(x))
    let configIndex: number

    configIndex = parentsIndex[NODE_LEVELS_INDEX.CONFIG_LEVEL]
    return cloneDeep(zoneLayouts[configIndex])
  }

  pasteConfiguration(
    evirLanguageDictionary: EvirLanguageDictionary,
    zoneLayouts: ZoneLayoutModel[],
    node: TreeFlatNode,
    copiedValue: ZoneLayoutModel,
    copiedIndex: string,
    selectedConfiguration: SelectedConfigurationModel,
    inspectionTypeNames: string[],
    pastedIndex = 0
  ) {
    const copiedParentsIndex: number[] = copiedIndex.split('/').map((x) => Number(x))
    const pasteParentsIndex: number[] = node
      ? node.id.split('/').map((x) => Number(x))
      : []
    const pasteLevel: number = node ? node.level + 1 : 0
    const indexChanged = []
    let // Copy
      copiedConfigIndex: number,
      copiedZoneIndex: number,
      copiedTagComponentIndex: number,
      copiedConditionIndex: number,
      // Paste
      pasteConfigIndex: number,
      pasteZoneIndex: number,
      pasteTagComponentIndex: number,
      pasteConditionIndex: number,
      componentIndexList: number[] = [],
      newComponentIndex: number,
      componentIndex: number,
      pasteComponents: ComponentModel

    copiedConfigIndex = copiedParentsIndex[NODE_LEVELS_INDEX.CONFIG_LEVEL]
    copiedZoneIndex = copiedParentsIndex[NODE_LEVELS_INDEX.ZONE_LEVEL]
    copiedTagComponentIndex = copiedParentsIndex[NODE_LEVELS_INDEX.COMPONENT_LEVEL]
    copiedConditionIndex = copiedParentsIndex[NODE_LEVELS_INDEX.CONDITION_LEVEL]

    pasteConfigIndex = pasteParentsIndex[NODE_LEVELS_INDEX.CONFIG_LEVEL]
    pasteZoneIndex = pasteParentsIndex[NODE_LEVELS_INDEX.ZONE_LEVEL]
    pasteTagComponentIndex = pasteParentsIndex[NODE_LEVELS_INDEX.COMPONENT_LEVEL]
    pasteConditionIndex = pasteParentsIndex[NODE_LEVELS_INDEX.CONDITION_LEVEL]

    switch (pasteLevel) {
      case NODE_LEVELS.CONFIG_LEVEL:
        selectedConfiguration.index++
        copiedValue.configZones.forEach((zone) => {
          zone.zoneInspectionTypes = inspectionTypeNames
            .map((name) =>
              this.langDictionaryService.getLangKeyByString(
                name,
                evirLanguageDictionary.languageStrings
              )
            )
            .filter((name) => equalsIgnoreCase(zone.zoneInspectionTypes, name))
        })
        zoneLayouts.splice(pastedIndex, 0, copiedValue)
        break

      case NODE_LEVELS.ZONE_LEVEL:
        componentIndexList = copiedValue.configZones[copiedZoneIndex].tagComponents
        componentIndexList.forEach((index) => {
          pasteComponents = copiedValue.components[index]
          zoneLayouts[pasteConfigIndex].components.push(pasteComponents)

          newComponentIndex =
            zoneLayouts[pasteConfigIndex].components.lastIndexOf(pasteComponents)
          copiedValue.configZones[copiedZoneIndex].tagComponents =
            copiedValue.configZones[copiedZoneIndex].tagComponents.map(
              (tagComponent, tagComponentIndex) => {
                if (tagComponent === index && !indexChanged.includes(tagComponentIndex)) {
                  indexChanged.push(tagComponentIndex)
                  return newComponentIndex
                }
                return tagComponent
              }
            )
        })

        zoneLayouts[pasteConfigIndex].configZones.splice(
          pastedIndex,
          0,
          this.updateAssetViewLocationForPastingZone(
            zoneLayouts[pasteConfigIndex].assetViewGrid,
            copiedValue.configZones[copiedZoneIndex]
          )
        )
        zoneLayouts[pasteConfigIndex].configZones[copiedZoneIndex].zoneInspectionTypes =
          inspectionTypeNames
            .map((name) =>
              this.langDictionaryService.getLangKeyByString(
                name,
                evirLanguageDictionary.languageStrings
              )
            )
            .filter((name) =>
              equalsIgnoreCase(
                zoneLayouts[pasteConfigIndex].configZones[copiedZoneIndex]
                  .zoneInspectionTypes,
                name
              )
            )
        break

      case NODE_LEVELS.COMPONENT_LEVEL:
        componentIndex =
          copiedValue.configZones[copiedZoneIndex].tagComponents[copiedTagComponentIndex]
        pasteComponents = copiedValue.components[componentIndex]
        zoneLayouts[pasteConfigIndex].components.push(pasteComponents)
        newComponentIndex =
          zoneLayouts[pasteConfigIndex].components.lastIndexOf(pasteComponents)
        zoneLayouts[pasteConfigIndex].configZones[pasteZoneIndex].tagComponents.splice(
          pastedIndex,
          0,
          newComponentIndex
        )
        break

      case NODE_LEVELS.CONDITION_LEVEL:
        const copyComponentIndex =
          copiedValue.configZones[copiedZoneIndex].tagComponents[copiedTagComponentIndex]
        const pasteCondition =
          copiedValue.components[copyComponentIndex].suggestedConditionLangKeys[
            copiedConditionIndex
          ]

        const pasteComponentIndex =
          zoneLayouts[pasteConfigIndex].configZones[pasteZoneIndex].tagComponents[
            pasteTagComponentIndex
          ]
        pasteComponents = cloneDeep(
          zoneLayouts[pasteConfigIndex].components[pasteComponentIndex]
        )
        pasteComponents.suggestedConditionLangKeys.splice(pastedIndex, 0, pasteCondition)
        zoneLayouts[pasteConfigIndex].components.push(pasteComponents)

        newComponentIndex =
          zoneLayouts[pasteConfigIndex].components.lastIndexOf(pasteComponents)
        zoneLayouts[pasteConfigIndex].configZones[pasteZoneIndex].tagComponents[
          pasteTagComponentIndex
        ] = newComponentIndex

        break

      default:
        break
    }
  }

  pasteConfigurationToOtherCompany(
    zoneLayouts: ZoneLayoutModel[],
    node: TreeFlatNode,
    copiedValue: ZoneLayoutModel,
    copiedIndex: string,
    selectedConfiguration: SelectedConfigurationModel,
    translationObject: TranslationObject,
    evirLanguageDictionary: EvirLanguageDictionary,
    evirLangDictionaryCopy: EvirLanguageDictionary,
    inspectionTypeNames: string[],
    pastedIndex = 0
  ) {
    const copiedParentsIndex: number[] = copiedIndex.split('/').map(x => Number(x))
    const pasteParentsIndex: number[] = node ? node.id.split('/').map(x => Number(x)) : []
    const pasteLevel: number = node ? node.level + 1 : 0
    const indexChanged = []
    let
      // Copy
      copiedConfigIndex: number,
      copiedZoneIndex: number,
      copiedTagComponentIndex: number,
      copiedConditionIndex: number,
      // Paste
      pasteConfigIndex: number,
      pasteZoneIndex: number,
      pasteTagComponentIndex: number,
      pasteConditionIndex: number,

      componentIndexList: number[] = [],
      newComponentIndex: number,
      componentIndex: number,
      pasteComponents: ComponentModel

    copiedConfigIndex = copiedParentsIndex[NODE_LEVELS_INDEX.CONFIG_LEVEL]
    copiedZoneIndex = copiedParentsIndex[NODE_LEVELS_INDEX.ZONE_LEVEL]
    copiedTagComponentIndex = copiedParentsIndex[NODE_LEVELS_INDEX.COMPONENT_LEVEL]
    copiedConditionIndex = copiedParentsIndex[NODE_LEVELS_INDEX.CONDITION_LEVEL]

    pasteConfigIndex = pasteParentsIndex[NODE_LEVELS_INDEX.CONFIG_LEVEL]
    pasteZoneIndex = pasteParentsIndex[NODE_LEVELS_INDEX.ZONE_LEVEL]
    pasteTagComponentIndex = pasteParentsIndex[NODE_LEVELS_INDEX.COMPONENT_LEVEL]
    pasteConditionIndex = pasteParentsIndex[NODE_LEVELS_INDEX.CONDITION_LEVEL]

    switch (pasteLevel) {
      case NODE_LEVELS.CONFIG_LEVEL:

        selectedConfiguration.index++
        copiedValue.zoneLayoutLangKey = this.handleRebuildLangString(
          copiedValue.zoneLayoutLangKey, translationObject, evirLanguageDictionary,
          evirLangDictionaryCopy, LANGUAGE_CONFIG_TYPES.CONFIGURATION
        )

        copiedValue.configZones.forEach((zone) => {
          zone.tagLangKey = this.handleRebuildLangString(
            zone.tagLangKey,
            translationObject,
            evirLanguageDictionary,
            evirLangDictionaryCopy,
            LANGUAGE_CONFIG_TYPES.ZONE
          )

          zone.tagTypeLangKey = this.handleRebuildLangString(
            zone.tagTypeLangKey,
            translationObject,
            evirLanguageDictionary,
            evirLangDictionaryCopy,
            LANGUAGE_CONFIG_TYPES.ZONE
          )

          const zoneInspectionTypeNames = zone.zoneInspectionTypes.map((inspectionType) =>
            this.langDictionaryService.convertLangKeyToString(
              inspectionType,
              evirLangDictionaryCopy
            )
          )

          zone.zoneInspectionTypes = inspectionTypeNames
            .filter((name) => equalsIgnoreCase(zoneInspectionTypeNames, name))
            .map((name) =>
              this.handleRebuildLangString(
                this.langDictionaryService.getLangKeyByString(
                  name,
                  evirLangDictionaryCopy.languageStrings
                ),
                translationObject,
                evirLanguageDictionary,
                evirLangDictionaryCopy
              )
            )
        })

        copiedValue.components.forEach((component) => {
          component.componentLangKey = this.handleRebuildLangString(
            component.componentLangKey,
            translationObject,
            evirLanguageDictionary,
            evirLangDictionaryCopy,
            LANGUAGE_CONFIG_TYPES.COMPONENT
          )

          component.suggestedConditionLangKeys.forEach((condition, conditionIndex) => {
            component.suggestedConditionLangKeys[conditionIndex] =
              this.handleRebuildLangString(
                condition,
                translationObject,
                evirLanguageDictionary,
                evirLangDictionaryCopy,
                LANGUAGE_CONFIG_TYPES.CONDITION
              )
          })
        })
        zoneLayouts.splice(pastedIndex, 0, copiedValue)

        break

      case NODE_LEVELS.ZONE_LEVEL:

        copiedValue.configZones[copiedZoneIndex].tagLangKey = this.handleRebuildLangString(
          copiedValue.configZones[copiedZoneIndex].tagLangKey, translationObject,
          evirLanguageDictionary, evirLangDictionaryCopy, LANGUAGE_CONFIG_TYPES.ZONE
        )
        copiedValue.configZones[copiedZoneIndex].tagTypeLangKey = this.handleRebuildLangString(
          copiedValue.configZones[copiedZoneIndex].tagTypeLangKey, translationObject,
          evirLanguageDictionary, evirLangDictionaryCopy, LANGUAGE_CONFIG_TYPES.ZONE
        )

        copiedValue.configZones[copiedZoneIndex].zoneInspectionTypes = inspectionTypeNames.map(name => this.langDictionaryService.getLangKeyByString(name, evirLanguageDictionary.languageStrings)).filter(name =>
          equalsIgnoreCase(copiedValue.configZones[copiedZoneIndex].zoneInspectionTypes, name)
        )

        componentIndexList = copiedValue.configZones[copiedZoneIndex].tagComponents
        componentIndexList.forEach(index => {
          copiedValue.components[index].componentLangKey = this.handleRebuildLangString(
            copiedValue.components[index].componentLangKey, translationObject, evirLanguageDictionary,
            evirLangDictionaryCopy, LANGUAGE_CONFIG_TYPES.COMPONENT
          )
          copiedValue.components[index].suggestedConditionLangKeys.forEach((condition, conditionIndex) => {
            copiedValue.components[index].suggestedConditionLangKeys[conditionIndex] = this.handleRebuildLangString(
              condition, translationObject, evirLanguageDictionary, evirLangDictionaryCopy, LANGUAGE_CONFIG_TYPES.CONDITION
            )
          })

          pasteComponents = copiedValue.components[index]
          zoneLayouts[pasteConfigIndex].components.push(pasteComponents)

          newComponentIndex = zoneLayouts[pasteConfigIndex].components.lastIndexOf(pasteComponents)
          copiedValue.configZones[copiedZoneIndex].tagComponents = copiedValue.configZones[copiedZoneIndex].tagComponents.map((tagComponent, tagComponentIndex) => {
            if (tagComponent === index && !indexChanged.includes(tagComponentIndex)) {
              indexChanged.push(tagComponentIndex)
              return newComponentIndex
            }
            return tagComponent
          })

        })

        zoneLayouts[pasteConfigIndex].configZones.splice(pastedIndex, 0, this.updateAssetViewLocationForPastingZone(zoneLayouts[pasteConfigIndex].assetViewGrid, copiedValue.configZones[copiedZoneIndex]))
        break

      case NODE_LEVELS.COMPONENT_LEVEL:

        componentIndex = copiedValue.configZones[copiedZoneIndex].tagComponents[copiedTagComponentIndex]
        copiedValue.components[componentIndex].componentLangKey = this.handleRebuildLangString(
          copiedValue.components[componentIndex].componentLangKey, translationObject,
          evirLanguageDictionary, evirLangDictionaryCopy, LANGUAGE_CONFIG_TYPES.COMPONENT
        )
        pasteComponents = copiedValue.components[componentIndex]
        pasteComponents.suggestedConditionLangKeys.forEach((condition, conditionIndex) => {
          pasteComponents.suggestedConditionLangKeys[conditionIndex] = this.handleRebuildLangString(
            condition, translationObject, evirLanguageDictionary,
            evirLangDictionaryCopy, LANGUAGE_CONFIG_TYPES.CONDITION
          )
        })
        zoneLayouts[pasteConfigIndex].components.push(pasteComponents)
        newComponentIndex = zoneLayouts[pasteConfigIndex].components.lastIndexOf(pasteComponents)
        zoneLayouts[pasteConfigIndex].configZones[pasteZoneIndex].tagComponents.splice(pastedIndex, 0, newComponentIndex)
        break

      case NODE_LEVELS.CONDITION_LEVEL:

        const copyComponentIndex = copiedValue.configZones[copiedZoneIndex].tagComponents[copiedTagComponentIndex]
        let pasteCondition = copiedValue.components[copyComponentIndex].suggestedConditionLangKeys[copiedConditionIndex]
        pasteCondition = this.handleRebuildLangString(
          pasteCondition, translationObject, evirLanguageDictionary,
          evirLangDictionaryCopy, LANGUAGE_CONFIG_TYPES.CONDITION
        )

        const pasteComponentIndex = zoneLayouts[pasteConfigIndex].configZones[pasteZoneIndex].tagComponents[pasteTagComponentIndex]
        pasteComponents = cloneDeep(zoneLayouts[pasteConfigIndex].components[pasteComponentIndex])
        pasteComponents.suggestedConditionLangKeys.splice(pastedIndex, 0, pasteCondition)
        zoneLayouts[pasteConfigIndex].components.push(pasteComponents)

        newComponentIndex = zoneLayouts[pasteConfigIndex].components.lastIndexOf(pasteComponents)
        zoneLayouts[pasteConfigIndex].configZones[pasteZoneIndex].tagComponents[pasteTagComponentIndex] = newComponentIndex

        break

      default:
        break
    }
  }

  handleRebuildLangString(
    copyUUID: string,
    translationObject: TranslationObject,
    evirLanguageDictionary: EvirLanguageDictionary,
    evirLangDictionaryCopy: EvirLanguageDictionary,
    configType: string = LANGUAGE_CONFIG_TYPES.INSPECTION_TYPE,
    languageCode: string = LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH]
  ) {

    if (!copyUUID) {
      throw new UndefinedOrNullUUIDValueError('UUID value is invalid')
    }

    const copyStringObject = evirLangDictionaryCopy.languageStrings[copyUUID]

    let currentUUID = this.langDictionaryService.getLangKeyByString(copyStringObject.translations[languageCode],
      translationObject, copyStringObject.configType, languageCode)
    let currentStringObject = currentUUID && translationObject[currentUUID]
    if (!currentUUID) {
      currentUUID = this.langDictionaryService.getLangKeyByString(copyStringObject.translations[languageCode],
        evirLanguageDictionary.languageStrings, copyStringObject.configType, languageCode)
      currentStringObject = currentUUID && evirLanguageDictionary.languageStrings[currentUUID]
    }

    /** Compare with all supported languages */
    if (isEqual(copyStringObject, currentStringObject)) {
      return currentUUID
    }

    const languageValue = copyStringObject || {
      translations: {
        [languageCode]: ''
      },
      configType: configType
    } as TranslationObjectValue

    return this.langDictionaryService.createNewTranslationsObject(languageValue, translationObject, configType)
  }

  parseFromTemplateLibrary(
    node: TreeNode, pasteId: string, zoneLayouts: ZoneLayoutModel[], translationObject: TranslationObject,
    evirLangDictionary: EvirLanguageDictionary, selectedConfiguration: SelectedConfigurationModel,
    includeSubLayers: boolean = true, insertAtLast: boolean = false, inspectionTypeNames: string[]
  ) {
    const pastedParentsIndex: number[] = pasteId.split('/').map(x => Number(x))
    const pastedLevel = pastedParentsIndex.length - 2
    const configIndex = pastedParentsIndex[NODE_LEVELS_INDEX.CONFIG_LEVEL]
    const zoneIndex = pastedParentsIndex[NODE_LEVELS_INDEX.ZONE_LEVEL]
    const componentIndex = pastedParentsIndex[NODE_LEVELS_INDEX.COMPONENT_LEVEL]
    const conditionIndex = pastedParentsIndex[NODE_LEVELS_INDEX.CONDITION_LEVEL]
    let pastedValue
    switch (pastedLevel) {
      case NODE_LEVELS.CONFIG_LEVEL:

        if (selectedConfiguration.index >= configIndex) {
          selectedConfiguration.index++
        }
        pastedValue = this.parseToConfigModel(node.value, translationObject, evirLangDictionary, includeSubLayers, inspectionTypeNames)
        zoneLayouts.splice(configIndex, 0, pastedValue)
        break

      case NODE_LEVELS.ZONE_LEVEL:

        pastedValue = this.parseToZoneModel(node.value, translationObject, evirLangDictionary,
          zoneLayouts[configIndex].components, zoneLayouts[configIndex].assetViewId, includeSubLayers, inspectionTypeNames)
        if (insertAtLast) {
          zoneLayouts[configIndex].configZones.push(pastedValue)
        } else {
          zoneLayouts[configIndex].configZones.splice(zoneIndex, 0, pastedValue)
        }
        break

      case NODE_LEVELS.COMPONENT_LEVEL:

        pastedValue = this.getTagComponentIndex(node.value, zoneLayouts[configIndex].components,
          translationObject, evirLangDictionary, includeSubLayers)
        if (insertAtLast) {
          zoneLayouts[configIndex].configZones[zoneIndex].tagComponents.push(pastedValue)
        } else {
          zoneLayouts[configIndex].configZones[zoneIndex].tagComponents.splice(componentIndex, 0, pastedValue)
        }
        break

      case NODE_LEVELS.CONDITION_LEVEL:

        pastedValue = this.langDictionaryService.getOrCreateLangKey(node.value.name, translationObject, evirLangDictionary, LANGUAGE_CONFIG_TYPES.CONDITION)
        const component = zoneLayouts[configIndex].configZones[zoneIndex].tagComponents[componentIndex]
        if (insertAtLast) {
          zoneLayouts[configIndex].components[component].suggestedConditionLangKeys.push(pastedValue)
        } else {
          zoneLayouts[configIndex].components[component].suggestedConditionLangKeys.splice(conditionIndex, 0, pastedValue)
        }
        break

      default:
        break
    }
  }

  parseToZoneModel(
    piece: ZonePieceModel, translationObject: TranslationObject, evirLangDictionary: EvirLanguageDictionary,
    listComponents: ComponentModel[], assetViewId: string, includeSubLayers: boolean = true, inspectionTypeNames: string[]
  ): ConfigZoneModel {
    const tagLangKey = piece.tagLangKey || this.langDictionaryService.getOrCreateLangKey(piece.tagNameKey, translationObject, evirLangDictionary, LANGUAGE_CONFIG_TYPES.ZONE, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH])

    const tagTypeLangKey = this.langDictionaryService.getOrCreateLangKey(piece.tagType,
      translationObject, evirLangDictionary, LANGUAGE_CONFIG_TYPES.ZONE, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH])

    const formatComponent = (component: ComponentPieceModel): ComponentPieceModel => {
      let { suggestedConditions, suggestedConditionLangKeys, ...rest } = component

      if (suggestedConditions) {
        suggestedConditionLangKeys = suggestedConditions.map((condition, index) => {
          return suggestedConditionLangKeys && suggestedConditionLangKeys[index]
            ? suggestedConditionLangKeys[index]
            : this.langDictionaryService.getOrCreateLangKey(condition, translationObject, evirLangDictionary, LANGUAGE_CONFIG_TYPES.CONDITION, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH])
        })
      }
      return {
        ...rest,
        suggestedConditionLangKeys, /** Always existing at least one condition in a component */
      }
    }

    const zone = {
      tagLangKey: tagLangKey,
      tagNumber: piece.tagNumber,
      tagComponents: includeSubLayers
        ? piece.tagComponents.map(component => this.getTagComponentIndex(formatComponent(component), listComponents, translationObject, evirLangDictionary))
        : [],
      tagTypeLangKey: tagTypeLangKey,
      zoneInspectionTypes: isValidArray(piece.zoneInspectionTypes)
        ? inspectionTypeNames
          .map(name => this.langDictionaryService.getLangKeyByString(name, evirLangDictionary.languageStrings))
          .filter(nameLangKey => equalsIgnoreCase(piece.zoneInspectionTypes, nameLangKey))
        : [],
    } as ConfigZoneModel

    return assetViewId ? { ...zone, assetViewLocation: piece.assetViewLocation } : zone
  }

  parseToComponentModel(
    piece: ComponentPieceModel, translationObject: TranslationObject,
    evirLangDictionary: EvirLanguageDictionary, includeSubLayers: boolean = true
  ): ComponentModel {

    const componentLangKey = piece.componentLangKey || this.langDictionaryService.getOrCreateLangKey(piece.componentNameKey, translationObject, evirLangDictionary, LANGUAGE_CONFIG_TYPES.COMPONENT, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH])

    return {
      componentLangKey: componentLangKey,
      maxSeverity: piece.maxSeverity,
      minSeverity: piece.minSeverity,
      suggestedConditionLangKeys: includeSubLayers ? piece.suggestedConditionLangKeys : []
    } as ComponentModel
  }

  parseToConfigModel(
    piece: ConfigurationPieceModel, translationObject: TranslationObject,
    evirLangDictionary: EvirLanguageDictionary, includeSubLayers: boolean = true, inspectionTypeNames: string[]
  ): ZoneLayoutModel {
    const zoneLayoutLangKey = piece.nameLangKey || this.langDictionaryService.getOrCreateLangKey(piece.zoneLayoutLanguageKey, translationObject, evirLangDictionary, LANGUAGE_CONFIG_TYPES.CONFIGURATION, LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH])

    const config: ZoneLayoutModel = {
      assetType: piece.assetType,
      zoneLayoutLangKey: zoneLayoutLangKey,
      configZones: [],
      components: []
    }
    if (includeSubLayers) {
      piece.configZones.forEach(zone => {
        const newZone = this.parseToZoneModel(zone, translationObject, evirLangDictionary, config.components, piece.assetViewId, includeSubLayers, inspectionTypeNames)
        config.configZones.push(newZone)
      })
    }

    return piece.assetViewId ? { ...config, assetViewId: piece.assetViewId, assetViewGrid: piece.assetViewGrid } : config
  }

  getTagComponentIndex(
    tagComponent: ComponentPieceModel, listComponent: ComponentModel[],
    translationObject: TranslationObject, evirLangDictionary: EvirLanguageDictionary, includeSubLayers: boolean = true
  ) {
    const parseComponent = this.parseToComponentModel(tagComponent, translationObject, evirLangDictionary, includeSubLayers)
    const componentIndex = listComponent.findIndex(component => isEqual(component, parseComponent))

    if (componentIndex !== -1) {
      return componentIndex
    }

    listComponent.push(parseComponent)
    return listComponent.length - 1
  }

  createMissingInspectionTypeList(
    inspectionTypeNames: string[],
    copiedInspectionByNameMapping: Record<string, InspectionDetailModel>,
    copiedCompanyId: string,
    currentCompanyId: string,
    translationObject: TranslationObject,
    evirLanguageDictionary: EvirLanguageDictionary,
    copiedEvirLanguageDictionary: EvirLanguageDictionary,
  ): InspectionDetailModel[] {
    return inspectionTypeNames
      .map((name) => {
        const copiedInspectionType: CopiedInspectionTypeModel = {
          companyId: copiedCompanyId,
          inspectionTypeName: name,
          inspectionTypeData: copiedInspectionByNameMapping[name],
          evirLanguageDictionary: copiedEvirLanguageDictionary,
        }
        // Should rebuild langkey with new copied inspection types.
        return this.createPastedInspectionType(
          copiedInspectionType,
          currentCompanyId,
          '',
          translationObject,
          evirLanguageDictionary,
          copiedEvirLanguageDictionary
        )
      })
  }

  createPastedInspectionType(
    copiedInspectionType: CopiedInspectionTypeModel,
    currentCompanyId: string,
    newInspectionTypeName: string,
    translationObject: TranslationObject,
    evirLanguageDictionary: EvirLanguageDictionary,
    evirLangDictionaryCopy: EvirLanguageDictionary
  ): InspectionDetailModel {
    const newName = newInspectionTypeName || copiedInspectionType.inspectionTypeName
    const newCopiedName = trimAll(newName)
    if (copiedInspectionType.companyId === currentCompanyId) {
      return {
        ...copiedInspectionType.inspectionTypeData,
        inspectionDetailLangKey: this.langDictionaryService.getOrCreateLangKey(newCopiedName, translationObject, evirLanguageDictionary),
      }
    }
    const labelNameKey = newInspectionTypeName
      ? this.langDictionaryService.getOrCreateLangKey(newInspectionTypeName, translationObject, evirLanguageDictionary)
      : this.handleRebuildLangString(
        copiedInspectionType.inspectionTypeData.inspectionDetailLangKey, translationObject, evirLanguageDictionary, evirLangDictionaryCopy, LANGUAGE_CONFIG_TYPES.FORM_DATA)
    const descriptionNameKey = this.handleRebuildLangString(
      copiedInspectionType.inspectionTypeData.inspectionDescriptionLangKey, translationObject, evirLanguageDictionary, evirLangDictionaryCopy, LANGUAGE_CONFIG_TYPES.FORM_DATA)
    const copiedFormData = copiedInspectionType.inspectionTypeData.formData
    const newFormData = isValidArray(copiedFormData)
      ? {
        formData: copiedFormData.map(formValue => {
          const selectField = isValidArray(formValue.selectLangKeys)
            ? { selectLangKeys: formValue.selectLangKeys.map(value => this.handleRebuildLangString(value, translationObject, evirLanguageDictionary, evirLangDictionaryCopy, LANGUAGE_CONFIG_TYPES.FORM_DATA)) }
            : {}
          return {
            ...formValue,
            fieldNameLangKey: this.handleRebuildLangString(formValue.fieldNameLangKey,
              translationObject, evirLanguageDictionary, evirLangDictionaryCopy, LANGUAGE_CONFIG_TYPES.FORM_DATA),
            hintLangKey: this.handleRebuildLangString(formValue.hintLangKey,
              translationObject, evirLanguageDictionary, evirLangDictionaryCopy, LANGUAGE_CONFIG_TYPES.FORM_DATA),
            ...selectField,
          }
        })
      }
      : {}

    return {
      ...copiedInspectionType.inspectionTypeData,
      inspectionDetailLangKey: labelNameKey,
      inspectionDescriptionLangKey: descriptionNameKey,
      ...newFormData
    }
  }

  collectInspectionsBelongToAnAsset(copiedNode: TreeFlatNode, zoneLayout: ZoneLayoutModel, inspectionDetails: InspectionDetailModel[], evirLanguageDictionary: EvirLanguageDictionary): Record<string, InspectionDetailModel> {
    const getInspectionByInspectionName = (allZoneInspectionTypes: string[], inspectionDetails: InspectionDetailModel[], evirLanguageDictionary: EvirLanguageDictionary) => {
      return inspectionDetails.reduce((acc, inspectionDetail) => {
        const nameLangKey = inspectionDetail.inspectionDetailLangKey
        const name = this.langDictionaryService.convertLangKeyToString(inspectionDetail.inspectionDetailLangKey, evirLanguageDictionary)
        if (name && equalsIgnoreCase(allZoneInspectionTypes, nameLangKey)) {
          acc[name] = inspectionDetail
        }
        return acc
      }, {})
    }

    switch (copiedNode.level) {
      case NODE_LEVELS.CONFIG_LEVEL:
        return getInspectionByInspectionName(
          (zoneLayout.configZones || []).reduce((acc, { zoneInspectionTypes }) => union(acc, zoneInspectionTypes || []), []),
          inspectionDetails,
          evirLanguageDictionary,
        )

      case NODE_LEVELS.ZONE_LEVEL:
        const [_, configIndex, zoneIndex] = copiedNode.id.split('/').map(x => Number(x))
        return getInspectionByInspectionName(
          (zoneLayout.configZones[zoneIndex].zoneInspectionTypes || []),
          inspectionDetails,
          evirLanguageDictionary,
        )
      default:
        return {}
    }
  }

  buildEvirLangDictionaryForConfiguration(configuration: ZoneLayoutModel, inspectionDetails: InspectionDetailModel[], mergedEvirLanguageDictionary: EvirLanguageDictionary) {
    if (!configuration) {
      return
    }

    const languageIds = this.collectConfigurationLanguageIds(configuration)
    const languageStrings = mergedEvirLanguageDictionary.languageStrings

    const copiedConfigLanguageStrings = [...new Set(languageIds)].filter(rawLangKey => !!rawLangKey)
      .reduce((acc, rawLangKey) => {
        acc[rawLangKey] = languageStrings[getLanguageKey(rawLangKey, mergedEvirLanguageDictionary)]
        return acc
      }, {})

    const copiedInspectionsLanguageStrings = (inspectionDetails || []).reduce((acc, inspectionDetail) => {
      const { languageStrings } = this.buildEvirLangDictionaryForCopiedInspection(inspectionDetail, null, mergedEvirLanguageDictionary)
      acc = { ...acc, ...languageStrings }
      return acc
    }, {})

    return {
      languageStrings: { ...copiedConfigLanguageStrings, ...copiedInspectionsLanguageStrings },
      legacyLanguageStrings: null,
    }
  }

  buildEvirLangDictionaryForCopiedInspection(
    inspectionType: InspectionDetailModel, inspectionTypeDetailRow: FormDataModel,
    mergedEvirLanguageDictionary: EvirLanguageDictionary): EvirLanguageDictionary {
    if (!inspectionType && !inspectionTypeDetailRow) {
      return
    }

    const languageIds = inspectionType
      ? this.collectCopiedInspectionTypeLanguageIds(inspectionType)
      : this.collectCopiedInspectionTypeDetailRowLanguageIds(inspectionTypeDetailRow)
    const languageStrings = mergedEvirLanguageDictionary.languageStrings

    const copiedLanguageStrings = [...new Set(languageIds)].filter(rawLangKey => !!rawLangKey)
      .reduce((acc, rawLangKey) => ({
        ...acc,
        [rawLangKey]: languageStrings[getLanguageKey(rawLangKey, mergedEvirLanguageDictionary)]
      }), {})

    return {
      languageStrings: copiedLanguageStrings,
      legacyLanguageStrings: null,
    }
  }

  private updateAssetViewLocationForPastingZone(assetViewGrid: number[], pastedConfigZone: ConfigZoneModel): ConfigZoneModel {
    const { assetViewLocation, ...others } = pastedConfigZone

    if (!assetViewGrid) {
      return others
    }

    pastedConfigZone.assetViewLocation = assetViewLocation
      ? assetViewLocation.map((value, index) => getLocationWithLimit(index, value, assetViewGrid))
      : [0, 0]

    return pastedConfigZone
  }

  private collectCopiedInspectionTypeDetailRowLanguageIds(inspectionTypeDetailRow: FormDataModel): string[] {
    return [
      inspectionTypeDetailRow.fieldNameLangKey,
      inspectionTypeDetailRow.hintLangKey,
      ...(inspectionTypeDetailRow.selectLangKeys || []),
    ]
  }

  private collectCopiedInspectionTypeLanguageIds(inspectionType: InspectionDetailModel): string[] {
    return [
      inspectionType.inspectionDetailLangKey,
      inspectionType.inspectionDescriptionLangKey,
      ...[].concat.apply([], (inspectionType.formData || []).map(this.collectCopiedInspectionTypeDetailRowLanguageIds))
    ]
  }

  private collectZoneLanguageIds(zone: ConfigZoneModel): string[] {
    return [
      zone.tagLangKey,
      zone.tagTypeLangKey,
    ]
  }

  private collectComponentLanguageIds(component: ComponentModel): string[] {
    return [
      component.componentLangKey,
      ...(component.suggestedConditionLangKeys || [])
    ]
  }

  private collectConfigurationLanguageIds(configuration: ZoneLayoutModel): string[] {
    return [
      configuration.zoneLayoutLangKey,
      ...[].concat.apply([], (configuration.configZones || []).map(this.collectZoneLanguageIds)),
      ...[].concat.apply([], (configuration.components || []).map(this.collectComponentLanguageIds)),
    ]
  }
}
