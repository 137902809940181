<mat-form-field appearance="outline"
                [style.width.px]="250">
  <mat-label>{{title}}</mat-label>
  <mat-select [formControl]="optionControl"
              [placeholder]="placeholder"
              (selectionChange)="handleSelectionChange($event.value)"
              [multiple]="multiple">
    <mat-option *ngFor="let option of options"
                class="app-option"
                [value]="option.value">{{option.title}}</mat-option>
  </mat-select>
</mat-form-field>
