<ng-container *ngIf="dataSource && treeControl">
  <div class="template-library">
    <mat-tree class="tree-list thin-scrollbar" cdkDropList [dataSource]="dataSource" [treeControl]="treeControl"
      id="template-library-tree" cdkDropListConnectedTo="configuration-tree">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding  [cdkDragDisabled]="node.level !== 0" matTreeNodePaddingIndent="20"
        class="template-library-box" cdkDrag [cdkDragData]="node" [ngClass]="{'node-parent': node.level === 0, 'tree-box': true}">
        <button mat-icon-button disabled></button>
        <div class="node-content truncate-text" [attr.id]="node.id"
          matTooltip="{{node.name + (node | addZoneTag) + (node | addZoneInspectionTypes : zoneNameList : languageDictionary)}}"
          [matTooltipDisabled]="tooltipDisabledStatus" [matTooltipShowDelay]="tooltipDelayTime"
          (mouseover)="tooltipDisabledStatus=isDisabledTooltip(node.id)">
          <span [attr.id]="'piece-name ' + node.id">
            <ng-container *ngTemplateOutlet="nodeName; context: { $implicit: node }"></ng-container>
          </span>
        </div>
        <app-menu *ngIf="!isMenuHidden && node.level === 0 && node.siblingIndex !== 0"
          [menuOptions]="displayMenuOptions"
          (clickOption)="handleClickOption($event, node)"></app-menu>
      </mat-tree-node>

      <mat-tree-node *matTreeNodeDef="let node;when: hasChild" matTreeNodePadding matTreeNodePaddingIndent="20" cdkDrag [cdkDragDisabled]="node.level !== 0"
      class="template-library-box" [cdkDragData]="node" [ngClass]="{'node-parent': node.level === 0, 'tree-box': true}">
        <button mat-icon-button matTreeNodeToggle [disableRipple]="true" (click)="expansionModel.toggle(node.id)"
          [attr.aria-label]="'toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <div class="node-content truncate-text" [attr.id]="node.id"
          matTooltip="{{node.name + (node | addZoneTag) + (node | addZoneInspectionTypes : zoneNameList : languageDictionary)}}"
          [matTooltipDisabled]="tooltipDisabledStatus" [matTooltipShowDelay]="tooltipDelayTime"
          (mouseover)="tooltipDisabledStatus=isDisabledTooltip(node.id)">
          <span [attr.id]="'piece-name ' + node.id">
            <ng-container *ngTemplateOutlet="nodeName; context: { $implicit: node }"></ng-container>
          </span>
        </div>
        <app-menu *ngIf="!isMenuHidden && node.level === 0 && node.siblingIndex !== 0"
          [menuOptions]="displayMenuOptions"
          (clickOption)="handleClickOption($event, node)"></app-menu>
      </mat-tree-node>
    </mat-tree>
  </div>
</ng-container>

<ng-template #nodeName let-node>
  <span [attr.id]="'piece-name ' + node.id">{{ node.name }}</span>
</ng-template>
