<div id="asset-zone-map-block"
  class="asset-zone-map"
  [ngClass]="{'loading': loading && !assetViewBackground}">
  <div class="asset-zone-map-title">
    <div class="title">{{'MAP_VIEW_TITLE' | translate}}</div>
  </div>
  <ng-container *ngIf="assetViewBackground !== null && assetViewBackground!== '' && selectedZoneMap">
    <div class="asset-zone-name"
      [matTooltip]="assetZoneName">{{assetZoneName | uppercase}}</div>
    <ng-container *ngIf="assetViewBackground && languageDictionary && inputForm && inputForm.controls.inspectionTypes">
      <div class="inspection-type">
        <form class="inspection-type-form"
          [formGroup]="inputForm">
          <mat-form-field appearance="outline"
            class="field-inspection-type"
            floatLabel="always"
            [style.width.px]="250">
            <mat-label>{{'INSPECTION_DROPDOWN' | translate}}</mat-label>
            <mat-select focused="false"
              [formControl]="inputForm.controls.inspectionTypes"
              [disabled]="inspectionTypeOptions.length <= 1"
              multiple="true"
              [placeholder]="('CHOOSE_OPTION_PLACEHOLDER' | translate)"
              (valueChange)="handleChangeInspectionTypes($event)">
              <mat-select-trigger
                *ngIf="inspectionTypeOptions.length > 1 && inputForm.controls.inspectionTypes.value.length === inspectionTypeOptions.length">
                {{'ALL' | translate}}
              </mat-select-trigger>
              <mat-option *ngFor="let option of inspectionTypeOptions"
                [value]="option.value">{{option.title}}</mat-option>
            </mat-select>
          </mat-form-field>
        </form>
      </div>
      <div class="asset-zone-box-grid thin-scrollbar">
        <app-grid-zone-box [languageDictionary]="languageDictionary"
          [svgSource]="assetViewBackground"
          [zoneMapData]="selectedZoneMap"
          (preparingData)="handlePreparingData($event)"
          (dropValue)="handleDropValue($event)"
          (hoveringBox)="handleHoverAssetZoneBox($event)"
          [inspectionTypes]="selectedInspectionTypesOption">
        </app-grid-zone-box>
      </div>
    </ng-container>
  </ng-container>
  <div class="asset-zone-box-grid error"
    *ngIf="(selectedZoneMap && assetViewBackground === '') || isNoDataAvailable">
    <div class="default-background"
      [ngClass]="{'valid-config': assetZoneName}">
        <div class="text-err-message">{{'ERROR_ASSET_BACKGROUND' | translate}}</div>
      </div>
  </div>
</div>
<div class="spinner" *ngIf="loading && !assetViewBackground">
  <mat-spinner [diameter]="44"></mat-spinner>
</div>
