import { Pipe, PipeTransform } from '@angular/core'

import { EvirLanguageDictionary, TreeFlatNode, ZoneNameModel } from 'src/app/components/tree/models/tree.model'
import { LanguageDictionaryHandlingService } from 'src/app/components/tree/services/language-dictionary-handling/language-dictionary-handling.service'
import { NODE_LEVELS, NODE_LEVELS_INDEX } from 'src/app/constants/internationalized-constants-en'

@Pipe({
  name: 'addZoneInspectionTypes'
})
export class AddZoneInspectionTypesPipe implements PipeTransform {

  constructor(public langDictionaryService: LanguageDictionaryHandlingService) {}

  transform(node: TreeFlatNode, zoneNameList: ZoneNameModel[], languageDictionary: EvirLanguageDictionary): string {
    if (node.level !== NODE_LEVELS.ZONE_LEVEL || !zoneNameList.length || !languageDictionary ||
      !(node.value && node.value.zoneInspectionTypes && node.value.zoneInspectionTypes.length)) {
      return ''
    }

    const nodeIndexes = node.id.split('/').map(value => Number(value))
    const duplicatedZoneNameList = zoneNameList.filter(zoneNameItem =>
      zoneNameItem.parentIndex === nodeIndexes[NODE_LEVELS_INDEX.CONFIG_LEVEL] && zoneNameItem.zoneName === node.name)
    let transformedValue = ''

    if (duplicatedZoneNameList.length > 1) {
    const translatedZoneInspectionTypes = node.value.zoneInspectionTypes.map(zoneInspectionType => this.langDictionaryService.convertLangKeyToString(zoneInspectionType, languageDictionary))
    transformedValue = ` - ${translatedZoneInspectionTypes.join(', ')}`
    }

    return transformedValue
  }

}
