import { formatDate } from '@angular/common'
import { Injectable } from '@angular/core'
import { Action, Selector, State, StateContext, Store } from '@ngxs/store'
import { TranslateService } from '@ngx-translate/core'
import { cloneDeep, isEqual, remove } from 'lodash'
import { of } from 'rxjs'
import { catchError, filter, tap } from 'rxjs/operators'
import {
  DEFAULT_MAX_IMAGE_COUNT,
  GRID_VIEW_POSITION_INDEX,
  INSTALLER_EXPORT_TYPE,
  LANGUAGE_CODE_BY_LANGUAGE_ID,
  LANGUAGE_CONFIG_TYPES,
  LANGUAGE_IDS,
  NODE_LEVELS_INDEX,
  TEMPLATE_LIBRARY_SORT_OPTIONS,
  TEMPLATE_TYPES_VALUE,
} from './constants/internationalized-constants-en'
import {
  TreeNode,
  ConfigurationModel,
  ZoneLayoutModel,
  TreeFlatNode,
  InstallerExportModel,
  SelectedConfigurationModel,
  InspectionDetailModel,
  AssetViewModel,
  AssetViewUploadModel,
  CopiedConfigModel,
  FormDataModel,
  TranslationObject,
  EvirLanguageDictionary,
} from './components/tree/models/tree.model'
import { CompaniesService } from './services/companies.service'
import { TimeFormatService } from './shared/time-format/time-format.service'
import { ErrorInformationModel, ErrorModel } from './views/companies/model'
import { UpdateAssetZoneDataModel, ZoneBoxModel } from './models'
import { DataConfigurationHandlingService } from './components/tree/services/data-configuration-handling/data-configuration-handling.service'
import { ExportDataConfigurationService } from './components/tree/services/export-data-configuration/export-data-configuration.service'
import {
  DisableUndoConfigurationChange,
  DisableUndoFeature,
  EnableUndoFeature,
  UndoConfigurationChange,
} from './modules/ngxs-history-plugin/actions'
import { ImportDataConfigurationService } from './components/tree/services/import-data-configuration/import-data-configuration.service'
import { XmlConverterService } from './components/tree/services/xml-converter/xml-converter.service'
import {
  ConfigurationPieceModel,
  FormDataPieceModel,
  InspectionDetailPieceModel,
  RawTemplateLibraryPieceModel,
  TemplateLibraryPieceModel,
} from './components/template-library/models/template-library.model'
import { TemplateLibraryPieceConvertingService } from './components/template-library/services/template-library-piece-converting/template-library-piece-converting.service'
import {
  InspectionType,
  InspectionTypeFormData,
} from './views/inspection-type/inspection-type.component'
import { LanguageDictionaryHandlingService } from './components/tree/services/language-dictionary-handling/language-dictionary-handling.service'
import {
  UndefinedOrNullLangValueError,
  UndefinedOrNullUUIDValueError,
} from './shared/error'
import { convertLangKeyToString, isValidArray, trimAll } from './utils/utils'

export type CompanyContext = {
  id: string
  name: string
}

export class GetCompanies {
  static readonly type = '[App] Get Companies'
  constructor() {}
}

export class SetCompanyContext {
  static readonly type = '[App] Set CompanyContext'
  constructor(public companyContext: CompanyContext) {}
}

export class GetConfiguration {
  static readonly type = '[App] Get Configuration'
  constructor(public companyId: string) {}
}

export class SetConfigurationId {
  static readonly type = '[App] Set Configuration Id'
  constructor(public configurationId: string) {}
}

export class UpdateDataConfiguration {
  static readonly type = '[App] Update Data Configuration'
  constructor(public treeNode: TreeNode) {}
}

export class UpdateDataConfigurationUndoable extends UpdateDataConfiguration {
  isUndoable = true
}

export class CreateLanguageDictionary {
  static readonly type = '[App] Create Language Dictionary'
  constructor(
    public treeNode: TreeNode,
    public languageDictionary: string,
    public configType: string = ''
  ) {}
}

export class CreateLanguageDictionaryUndoable extends CreateLanguageDictionary {
  isUndoable = true
}

export class DeleteDataConfiguration {
  static readonly type = '[App] Delete Data Configuration'
  constructor(public deleteNode: TreeNode) {}
}

export class DeleteDataConfigurationUndoable extends DeleteDataConfiguration {
  isUndoable = true
}

export class CreateNewDataConfiguration {
  static readonly type = '[App] Create New Data Configuration'
  constructor(
    public treeNode: TreeNode,
    public isReflect: boolean,
    public languageDictionary: string,
    public configType: string
  ) {}
  isUndoable = true
}

export class CreateDataNewForDeleteDataConfiguration {
  static readonly type = '[App] Create New Data For Delete Data Configuration'
  constructor(public deleteNode: TreeNode) {}
  isUndoable = true
}

export class ReorderDataConfiguration {
  static readonly type = '[App] Reorder Data Configuration'
  constructor(
    public treeNode: TreeNode,
    public newIndex: number,
    public isReflect?: boolean
  ) {}
  isUndoable = true
}

export class SetErrorType {
  static readonly type = '[App] Set Error Type'
  constructor(public httpError: boolean) {}
}

export class SetErrorInformation {
  static readonly type = '[App] Set Error Information'
  constructor(public errorInformation: ErrorInformationModel) {}
}

export class SetUserProfileId {
  static readonly type = '[App] Set User Profile Id'
  constructor(public profileId: string) {}
}

export class SaveConfiguration {
  static readonly type = '[App] Save Configuration'
  constructor() {}
}

export class PublishConfiguration {
  static readonly type = '[App] Publish Configuration'
  constructor() {}
}

export class TestConfiguration {
  static readonly type = '[App] Test Configuration'
  constructor() {}
}
export class InstallerExportDataConfiguration {
  static readonly type = '[App] Installer Export Configuration'
  constructor(
    public installerExportType: INSTALLER_EXPORT_TYPE,
    public isExportPiece: boolean,
    public node?: TreeFlatNode
  ) {}
}

export class GetLegacyAccountCode {
  static readonly type = '[App] Get Legacy Account Code'
  constructor(public companyId: string) {}
}

export class CloneDataConfiguration {
  static readonly type = '[App] Clone Data Configuration'
  constructor(public node: TreeFlatNode) {}
  isUndoable = true
}

export class SetExpandedStates {
  static readonly type = '[App] Set Expanded States'
  constructor(public expandedStates: string[]) {}
}
export class SelectConfiguration {
  static readonly type = '[App] Select Configuration'
  constructor(public node: TreeNode) {}
}

export class GetAssetZoneMapBackground {
  static readonly type = '[App] Get Asset Zone Map Background'
  constructor(public companyId: string, public assetViewId: string) {}
}
export class UpdateAssetZoneMapPosition {
  static readonly type = '[App] Update Asset Zone Map Position'
  constructor(public positionValue: UpdateAssetZoneDataModel) {}
  isUndoable = true
}
export class HoverAssetZoneMap {
  static readonly type = '[App] Hover Asset ZoneMap'
  constructor(public zoneBox: ZoneBoxModel) {}
}

export class SetWorkInProgressStatus {
  static readonly type = '[App] Set Work In Progress Status'
  constructor(public isWorkInProgress: boolean) {}
}

export class SetDraftStatus {
  static readonly type = '[App] Set Draft Status'
  constructor(public draft: boolean) {}
}
export class CopyConfiguration {
  static readonly type = '[App] Copy Configuration'
  constructor(public node: TreeFlatNode) {}
}
export class PasteConfiguration {
  static readonly type = '[App] Paste Configuration'
  constructor(public node: TreeFlatNode) {}
  isUndoable = true
}

export class PasteFromTemplateLibrary {
  static readonly type = '[App] Paste From Template Library'
  constructor(
    public node: TreeNode,
    public insertId: string,
    public includeSubLayers: boolean = true,
    public insertAtLast: boolean = false
  ) {}
  isUndoable = true
}

export class UpdateInspectionFormData {
  static readonly type = '[App] Update inspection form data'
  constructor(
    public inspectionIndex: number,
    public formDataIndex: number,
    public payload: InspectionTypeFormData
  ) {}
  isUndoable = true
}

export class ImportLegacyConfig {
  static readonly type = '[App] Set Import Legacy Config'
  constructor(public legacyConfigXmlList: string[]) {}
  isUndoable = true
}

export class DeleteBorderDataConfigurationImport {
  static readonly type = '[App] Delete Border Data Configuration Import'
  constructor() {}
}

export class SetFilterInspectionTypes {
  static readonly type = '[App] Set Filter Inspection Types'
  constructor(public inspectionTypes: string[]) {}
}

export class SetConfigurationChangedStatus {
  static readonly type = '[App] Set Configuration Changed Status'
  constructor(public isConfigurationChanged: boolean) {}
}

export class GetAssetViewList {
  static readonly type = '[App] Get Asset View List'
  constructor(public companyId: string) {}
}
export class UploadAssetMapView {
  static readonly type = '[App] Upload Asset Map View'
  constructor(public fileUpload: AssetViewUploadModel) {}
}

export class SelectInspectionType {
  static readonly type = '[App] Select inspection type'
  constructor(public index: number) {}
}

export class RemoveInspectionTypeDetailRow {
  static readonly type = '[App] Remove inspection type detail row'
  constructor(public index: number) {}
  isUndoable = true
}

export class GetTemplateLibraryPieces {
  static readonly type = '[App] Get Template Library Pieces'
  constructor() {}
}

export class UpdateFilterPieceType {
  static readonly type = '[App] Update Filter Piece Type'
  constructor(public newFilterPieceType: string) {}
}

export class UpdatePieceSortOption {
  static readonly type = '[App] Update Piece Sort Option'
  constructor(public newPieceSortOption: string) {}
}

export class UpdatePieceSearchText {
  static readonly type = '[App] Update Piece Search Text'
  constructor(public newSearchText: string) {}
}

export class DeleteTemplateLibraryPiece {
  static readonly type = '[App] Delete Template Library Piece'
  constructor(public pieceId: string) {}
}

export class UpdatePiece {
  static readonly type = '[App] Update Piece'
  constructor(
    public updateData: TemplateLibraryPieceModel,
    public id: string,
    public isAdmin: boolean
  ) {}
}

export class DeleteInspectionDetail {
  static readonly type = '[App] Delete Inspection Detail'
  isUndoable = true
  constructor(public index: number, public inspectionDetail: any) {}
}

export class GetBlankPieces {
  static readonly type = '[App] Get Blank Pieces'
  constructor() {}
}

export class AddTemplateLibraryPiece {
  static readonly type = '[App] Add Template Library Piece'
  constructor(
    public node: TreeFlatNode,
    public pieceName: string,
    public isIncludedAllSublayers: boolean,
    public isAdminViewOnly: boolean
  ) {}
}

export class SetUserEmail {
  static readonly type = '[App] Set User Email'
  constructor(public userEmail: string) {}
}

export class DropToInspectionTypeDetail {
  static readonly type = '[App] Drop To Inspection Type Detail'
  constructor(
    public inspectionIndex: number,
    public formDataIndex: number,
    public payload: InspectionTypeFormData
  ) {}
  isUndoable = true
}

export class DeleteFormLibraryPiece {
  static readonly type = '[App] Delete Form Library Piece'
  constructor(public pieceId: string) {}
}

export class AddInspectionType {
  static readonly type = '[App] Add Inspection Type'
  constructor(public rawInspectionType: InspectionDetailPieceModel) {}
  isUndoable = true
}

export class CloneBelowInspectionTypes {
  static readonly type = '[App] Clone Below Inspection Types'
  constructor(public index: number, public inspectionTypeName: string) {}
  isUndoable = true
}

export class CopyInspectionTypeDetailRow {
  static readonly type = '[App] Copy Inspection Type Detail Row'
  constructor(public inspectionTypeDetailRow: InspectionTypeFormData) {}
}

export class PasteInspectionTypeDetailRow {
  static readonly type = '[App] Paste Inspection Type Detail Row'
  constructor() {}
  isUndoable = true
}

export class UpdateInspectionType {
  static readonly type = '[App] Update Inspection Type'
  constructor(
    public inspectionIndex: number,
    public data: {
      label: string
      description: string
      zoneless: boolean
      category: string
    }
  ) {}
  isUndoable = true
}

export class ToggleInspectionAdditionalInformation {
  static readonly type = '[App] Toggle Inspection Additional Information'
  constructor(public inspectionIndex: number, public key: string) {}
  isUndoable = true
}

export class CopyInspectionType {
  static readonly type = '[App] Copy Inspection Type'
  constructor(
    public inspectionTypeIndex: number,
    public inspectionType: InspectionType
  ) {}
}

export class PasteInspectionType {
  static readonly type = '[App] Paste Inspection Type'
  constructor(public newInspectionTypeName: string = '') {}
  isUndoable = true
}

export class CreateNewInspectionType {
  static readonly type = '[App] Create New Inspection Type'
  constructor(
    public data: {
      label: string
      description: string
      zoneless: boolean
      category: string
    }
  ) {}
  isUndoable = true
}

export class AddPremadeFieldToFormLibrary {
  static readonly type = '[App] Add Premade Field To Form Library'
  constructor(
    public premadeFieldName: string,
    public isAdminViewOnly: boolean,
    public rawPremadeFieldData: InspectionTypeFormData
  ) {}
}

export class SetActiveCompanyId {
  static readonly type = '[App] Set Active Company Id'
  constructor(public activeCompanyId: string) {}
}

export class UpdateZoneInspectionType {
  static readonly type = '[App] Update Zone Inspection Type'
  constructor(
    public oldZoneInspectionType: string,
    public newZoneInspectionType: string
  ) {}
}

export class UpdateLanguageDictionary {
  static readonly type = '[App] Update Language Dictionary'
  constructor() {}
}

export type Ect2Data = Record<string, string | boolean>

export class CopiedInspectionTypeDetailRowModel {
  companyId: string
  rowData: FormDataModel
  evirLanguageDictionary: EvirLanguageDictionary
}

export class CopiedInspectionTypeModel {
  companyId: string
  inspectionTypeName: string
  inspectionTypeData: InspectionDetailModel
  evirLanguageDictionary: EvirLanguageDictionary
}

export class SetAccessStatus {
  static readonly type = '[App] Set Access Granted'
  constructor(public isAccessGranted: boolean = false) {}
}

export function transformToInspectionTypeFormData(
  formPieceValue: FormDataPieceModel
): InspectionTypeFormData {
  const { fieldNameLabelKey, hintKey, fieldNameLangKey, hintLangKey, ...rest } =
    formPieceValue

  return {
    ...rest,
    fieldNameLangKey: fieldNameLangKey || '',
    hintLangKey: hintLangKey || '',
    fieldNameLabel: fieldNameLabelKey,
    hint: hintKey,
  }
}

export class AppStateModel {
  companyContext: CompanyContext
  dataConfiguration: ZoneLayoutModel[]
  dataInspectionDetails: InspectionDetailModel[]
  companyId: string
  ect2Data: Ect2Data
  configType: string
  status: null
  error: ErrorModel
  zonarOwnerId: string
  configurationId: string
  selectedCompanyLegacy: any
  expandedStates: string[]
  selectedConfiguration: SelectedConfigurationModel
  assetViewBackground: string
  hoveringAssetZoneBox: ZoneBoxModel
  isConfigurationChanged: boolean
  isWorkInProgress: boolean
  draft: boolean
  isUndoable: boolean
  isActiveUndo: boolean
  /** This property is understood that current user is in Zonar Admin group or not */
  isAccessGranted: boolean
  filterInspectionTypes: string[]
  assetViewList: AssetViewModel[]
  updateAssetViewMapResponse: AssetViewUploadModel
  selectedInspectionTypeIndex: number
  copiedValue: CopiedConfigModel
  templateLibraryPieces: TemplateLibraryPieceModel[]
  filterPieceType: string
  pieceSortOption: string
  pieceSearchText: string
  blankPieces: TemplateLibraryPieceModel[]
  userEmail: string
  copiedInspectionDetailRow: CopiedInspectionTypeDetailRowModel
  copiedInspectionType: CopiedInspectionTypeModel
  activeCompanyId: string
  translationObject: TranslationObject
  evirLanguageDictionary: EvirLanguageDictionary
  languageCodes: string[]
  created: string
  version: string
  maxDefectImageCount: number
}

export let legacyConfigurationId: string = ''

@State<AppStateModel>({
  name: 'app',
  defaults: {
    companyContext: null,
    dataConfiguration: null,
    dataInspectionDetails: null,
    companyId: null,
    ect2Data: null,
    configType: null,
    status: null,
    error: null,
    zonarOwnerId: '',
    configurationId: '',
    selectedCompanyLegacy: null,
    expandedStates: [],
    selectedConfiguration: null,
    assetViewBackground: null,
    hoveringAssetZoneBox: null,
    isConfigurationChanged: null,
    isWorkInProgress: null,
    draft: null,
    isUndoable: false,
    isActiveUndo: false,
    isAccessGranted: false,
    filterInspectionTypes: [],
    assetViewList: [],
    updateAssetViewMapResponse: null,
    selectedInspectionTypeIndex: 0,
    copiedValue: null,
    templateLibraryPieces: [],
    filterPieceType: 'configuration',
    pieceSortOption: 'Sort by date added',
    pieceSearchText: '',
    blankPieces: [],
    userEmail: '',
    copiedInspectionDetailRow: null,
    copiedInspectionType: null,
    activeCompanyId: null,
    translationObject: null,
    evirLanguageDictionary: null,
    languageCodes: [],
    created: '',
    version: '',
    maxDefectImageCount: 0,
  },
})
@Injectable()
export class AppState {
  constructor(
    private companiesService: CompaniesService,
    private timeFormatService: TimeFormatService,
    private dataConfigurationHandlingService: DataConfigurationHandlingService,
    private exportDataConfigurationService: ExportDataConfigurationService,
    private importDataConfigurationService: ImportDataConfigurationService,
    private xmlConverterService: XmlConverterService,
    private pieceConvertingService: TemplateLibraryPieceConvertingService,
    private langDictionaryService: LanguageDictionaryHandlingService,
    private translateService: TranslateService,
    private store: Store
  ) {}

  @Selector()
  static getCompanyContext(state: AppStateModel) {
    return state.companyContext
  }

  @Selector()
  static getMaxImageCount(state: AppStateModel) {
    return state.maxDefectImageCount
  }

  @Selector()
  static getDataConfiguration(state: AppStateModel) {
    return state.dataConfiguration
  }

  @Selector()
  static getLanguageDictionary({
    evirLanguageDictionary,
    translationObject,
  }: Pick<AppStateModel, 'evirLanguageDictionary' | 'translationObject'>) {
    return {
      ...evirLanguageDictionary,
      languageStrings: {
        ...translationObject,
        ...evirLanguageDictionary.languageStrings,
      },
    }
  }

  @Selector()
  static getInspectionDetails(state: AppStateModel) {
    return state.dataInspectionDetails
  }

  @Selector()
  static getCompanyId(state: AppStateModel) {
    return state.companyId
  }

  @Selector()
  static getGlobalEct2Data(state: AppStateModel) {
    return state.ect2Data
  }

  @Selector()
  static getGlobalConfigType(state: AppStateModel) {
    return state.configType
  }

  @Selector()
  static getUserProfileId(state: AppStateModel) {
    return state.zonarOwnerId
  }

  @Selector()
  static getErrorType(state: AppStateModel) {
    return state.error.isHttpError
  }

  @Selector()
  static getErrorInformation(state: AppStateModel) {
    return state.error.errorInformation
  }

  @Selector()
  static getExpandedStates(state: AppStateModel) {
    return state.expandedStates
  }

  @Selector()
  static getSelectedConfiguration(state: AppStateModel) {
    return state.selectedConfiguration
  }

  @Selector()
  static getAssetViewBackground(state: AppStateModel) {
    return state.assetViewBackground
  }

  @Selector()
  static getHoveringAssetZoneBox(state: AppStateModel) {
    return state.hoveringAssetZoneBox
  }

  @Selector()
  static getConfigurationChangedStatus(state: AppStateModel) {
    return state.isConfigurationChanged
  }

  @Selector()
  static getWorkInProgressStatus(state: AppStateModel) {
    return state.isWorkInProgress
  }

  @Selector()
  static getDraftStatus(state: AppStateModel) {
    return state.draft
  }

  @Selector()
  static getUndoableStatus(state: AppStateModel) {
    return state.isUndoable
  }

  @Selector()
  static getActiveUndo(state: AppStateModel) {
    return state.isActiveUndo
  }

  @Selector()
  static getCopyNodeId(state: AppStateModel) {
    return state.copiedValue.originalCopyNodeId
  }

  @Selector()
  static getFilterInspectionTypes(state: AppStateModel) {
    return state.filterInspectionTypes
  }

  @Selector()
  static getAssetViewList(state: AppStateModel) {
    return state.assetViewList
  }

  @Selector()
  static getSelectedInspectionTypeIndex(state: AppStateModel) {
    return state.selectedInspectionTypeIndex
  }

  @Selector()
  static getFilteredPieces(state: AppStateModel) {
    const filteredPieces = state.templateLibraryPieces
      .filter((piece) => piece.pieceType === state.filterPieceType)
      .filter((piece) => state.isAccessGranted || !piece.admin)
      .filter(
        (item) =>
          item.name.toLowerCase().indexOf(state.pieceSearchText.trim().toLowerCase()) > -1
      )

    const pieceSortOption = state.pieceSortOption
    const compareTwoPieces = (
      firstPiece: TemplateLibraryPieceModel,
      secondPiece: TemplateLibraryPieceModel
    ) => {
      return pieceSortOption === TEMPLATE_LIBRARY_SORT_OPTIONS.SORT_BY_DATE_ADDED
        ? new Date(firstPiece.created).getTime() - new Date(secondPiece.created).getTime()
        : firstPiece.name.localeCompare(secondPiece.name)
    }
    const generateCompareFunc =
      (isDesc: boolean) =>
      (firstItem: TemplateLibraryPieceModel, secondItem: TemplateLibraryPieceModel) => {
        let left = firstItem
        let right = secondItem
        if (isDesc) {
          left = secondItem
          right = firstItem
        }
        return compareTwoPieces(left, right)
      }

    const compareFunc = generateCompareFunc(
      pieceSortOption === TEMPLATE_LIBRARY_SORT_OPTIONS.SORT_BY_DESCENDING_ALPHABET
    )
    return filteredPieces.sort(compareFunc)
  }

  @Selector()
  static getFilterPieceType(state: AppStateModel) {
    return state.filterPieceType
  }

  @Selector()
  static getPieceSearchText(state: AppStateModel) {
    return state.pieceSearchText
  }

  @Selector()
  static getTemplateLibraryPieces(state: AppStateModel) {
    return state.templateLibraryPieces
  }

  @Selector()
  static getBlankPiece(state: AppStateModel) {
    return state.blankPieces.filter((piece) => piece.pieceType === state.filterPieceType)
  }

  @Selector()
  static getFormLibraryPieces(state: AppStateModel) {
    return state.templateLibraryPieces
      .filter((piece) => piece.pieceType === TEMPLATE_TYPES_VALUE.FORM)
      .filter((piece) => state.isAccessGranted || !piece.admin)
  }

  @Selector()
  static getBlankFormLibraryPieces(state: AppStateModel) {
    return state.blankPieces.filter(
      (piece) => piece.pieceType === TEMPLATE_TYPES_VALUE.FORM
    )
  }

  @Selector()
  static getInspectionTypeLibraryPieces(state: AppStateModel) {
    return state.blankPieces.filter(
      (piece) => piece.pieceType === TEMPLATE_TYPES_VALUE.INSPECTION_TYPE
    )
  }

  @Selector()
  static getInspectionTypeNames(
    { dataInspectionDetails, ...languageDictionaryState }: Pick<
      AppStateModel,
      'dataInspectionDetails' | 'evirLanguageDictionary' | 'translationObject'
    >
  ) {
    const mergedLanguageDictionary = AppState.getLanguageDictionary(languageDictionaryState)

    return dataInspectionDetails.map(({ inspectionDetailLangKey }) =>
      convertLangKeyToString(inspectionDetailLangKey, mergedLanguageDictionary)
    )
  }

  @Selector()
  static getInspectionTypeOptions(state: AppStateModel) {
    const mergedLanguageDictionary = AppState.getLanguageDictionary(state)
    return state.dataInspectionDetails.map((inspectionType) => ({
      value: inspectionType.inspectionDetailLangKey,
      title: convertLangKeyToString(
        inspectionType.inspectionDetailLangKey,
        mergedLanguageDictionary
      ),
    }))
  }

  @Selector()
  static getInspectionDetailLangKeys(state: AppStateModel) {
    return state.dataInspectionDetails.map(
      (inspectionType) => inspectionType.inspectionDetailLangKey
    )
  }

  @Selector()
  static getCopiedInspectionTypeDetailRow(state: AppStateModel) {
    return state.copiedInspectionDetailRow
  }

  @Selector()
  static getCopiedInspectionType(state: AppStateModel) {
    return state.copiedInspectionType
  }

  @Selector()
  static getActiveCompanyId(state: AppStateModel) {
    return state.activeCompanyId
  }

  @Selector()
  static getRawLanguageDictionary(state: AppStateModel) {
    return {
      translationObject: state.translationObject,
      evirLanguageDictionary: state.evirLanguageDictionary,
    }
  }

  @Selector()
  static getLanguageCodes(state: AppStateModel) {
    return state.languageCodes
  }

  @Selector()
  static getConfigPackageVersion(state: AppStateModel) {
    return state.version
  }

  @Selector()
  static getConfigPackageCreatedDate(state: AppStateModel) {
    return state.created
  }

  @Selector()
  static getAccessStatus(state: AppStateModel) {
    return state.isAccessGranted
  }

  @Action(SetCompanyContext)
  setCompanyContext(
    { patchState }: StateContext<AppStateModel>,
    action: SetCompanyContext
  ) {
    patchState({
      companyContext: action.companyContext,
    })
  }

  @Action(GetConfiguration)
  getConfiguration(
    { patchState }: StateContext<AppStateModel>,
    action: GetConfiguration
  ) {
    if (legacyConfigurationId) {
      legacyConfigurationId = ''
    }

    patchState({
      dataConfiguration: null,
      dataInspectionDetails: null,
      selectedConfiguration: null,
      isConfigurationChanged: false,
      isWorkInProgress: false,
      draft: false,
      isUndoable: false,
      companyId: action.companyId,
      filterPieceType: 'configuration',
      pieceSortOption: 'Sort by date added',
      pieceSearchText: '',
      translationObject: null,
      evirLanguageDictionary: null,
      created: '',
      version: '',
    })

    return this.companiesService.getConfiguration(action.companyId).pipe(
      tap((configurationPackage: ConfigurationModel) => {
        try {
          patchState({
            dataConfiguration: configurationPackage.zoneLayouts || [],
            dataInspectionDetails: configurationPackage.inspectionDetails || [],
            companyId: configurationPackage.companyId || '',
            ect2Data: configurationPackage.ect2Data || {},
            configType: configurationPackage.configType || '',
            isWorkInProgress:
              configurationPackage.ect2Data &&
              typeof configurationPackage.ect2Data.isWorkInProgress === 'boolean'
                ? configurationPackage.ect2Data.isWorkInProgress
                : false,
            draft:
              typeof configurationPackage.draft === 'boolean'
                ? configurationPackage.draft
                : false,
            selectedInspectionTypeIndex: isValidArray(
              configurationPackage.inspectionDetails
            )
              ? 0
              : null,
            translationObject: configurationPackage.translationObject || {},
            evirLanguageDictionary:
              configurationPackage.evirLanguageDictionary ||
              ({} as EvirLanguageDictionary),
            languageCodes: configurationPackage.languageCodes || [
              'de-de',
              'en-us',
              'es-es',
              'fr-fr',
              'it-it',
            ],
            created: configurationPackage.created || '',
            version: configurationPackage.version || '',
            maxDefectImageCount:
              configurationPackage.maxDefectImageCount || DEFAULT_MAX_IMAGE_COUNT,
          })

          if (configurationPackage.id) {
            legacyConfigurationId = configurationPackage.id
            this.store.dispatch(new SetConfigurationId(configurationPackage.id))
          }
        } catch {
          console.error(
            `error parsing the configuration with id = ${configurationPackage.id}`
          )
        }
      })
    )
  }

  @Action(SetConfigurationId)
  SetConfigurationId(
    { patchState }: StateContext<AppStateModel>,
    action: SetConfigurationId
  ) {
    patchState({
      configurationId: action.configurationId,
    })
  }

  @Action(CreateLanguageDictionary)
  createLanguageDictionary(
    { patchState, getState }: StateContext<AppStateModel>,
    action: CreateLanguageDictionary
  ) {
    try {
      const translationObject = cloneDeep(getState().translationObject)
      const languageKey = this.langDictionaryService.createNewTranslationsObject(
        action.languageDictionary,
        translationObject,
        action.configType
      )

      patchState({
        translationObject: translationObject,
      })

      const newConfig = action.treeNode
      newConfig.value.id = languageKey
      this.store.dispatch(new UpdateDataConfiguration(newConfig))
    } catch (error) {
      if (error instanceof UndefinedOrNullLangValueError) {
        console.error(error)
      } else {
        throw error
      }
    }
  }

  @Action([UpdateDataConfiguration, UpdateDataConfigurationUndoable])
  updateDataConfiguration(
    { patchState, getState }: StateContext<AppStateModel>,
    action: UpdateDataConfiguration
  ) {
    const configData = cloneDeep(getState().dataConfiguration)
    const translationObject = cloneDeep(getState().translationObject)
    const evirLangDictionary = cloneDeep(getState().evirLanguageDictionary)
    this.dataConfigurationHandlingService.updateRawData(
      configData,
      action.treeNode,
      translationObject,
      evirLangDictionary
    )

    patchState({
      dataConfiguration: configData,
      translationObject: translationObject,
      isConfigurationChanged: true,
    })
  }

  @Action(CreateNewDataConfiguration)
  createNewDataConfiguration(
    { patchState, getState }: StateContext<AppStateModel>,
    action: CreateNewDataConfiguration
  ) {
    try {
      const configData = cloneDeep(getState().dataConfiguration)
      const newTreeNode = cloneDeep(action.treeNode)
      this.dataConfigurationHandlingService.createNewNodeToRawData(
        configData,
        newTreeNode
      )

      patchState({
        dataConfiguration: configData,
      })
      if (action.isReflect || action.treeNode.value.id) {
        this.store.dispatch(new UpdateDataConfiguration(newTreeNode))
      } else {
        this.store.dispatch(
          new CreateLanguageDictionary(
            newTreeNode,
            action.languageDictionary,
            action.configType
          )
        )
      }
    } catch (error) {
      if (error instanceof UndefinedOrNullLangValueError) {
        console.error(error)
      } else {
        throw error
      }
    }
  }

  @Action(CreateDataNewForDeleteDataConfiguration)
  createDataNewForDeleteDataConfiguration(
    { patchState, getState }: StateContext<AppStateModel>,
    action: CreateDataNewForDeleteDataConfiguration
  ) {
    const configData = cloneDeep(getState().dataConfiguration)
    const newTreeNode = cloneDeep(action.deleteNode)
    this.dataConfigurationHandlingService.createNewNodeToRawData(configData, newTreeNode)
    patchState({
      dataConfiguration: configData,
    })
    this.store.dispatch(new DeleteDataConfiguration(newTreeNode))
  }

  @Action(DeleteDataConfiguration)
  deleteDataConfiguration(
    { patchState, getState }: StateContext<AppStateModel>,
    action: DeleteDataConfiguration
  ) {
    const configData = cloneDeep(getState().dataConfiguration) as ZoneLayoutModel[]
    this.dataConfigurationHandlingService.deleteDataConfiguration(
      configData,
      action.deleteNode
    )
    if (!configData.length) {
      patchState({
        selectedConfiguration: null,
        assetViewBackground: null,
      })
    }
    patchState({
      dataConfiguration: configData,
      isConfigurationChanged: true,
    })
  }

  @Action(ReorderDataConfiguration)
  reorderDataConfiguration(
    { patchState, getState }: StateContext<AppStateModel>,
    action: ReorderDataConfiguration
  ) {
    const configData = cloneDeep(getState().dataConfiguration)
    if (!action.isReflect) {
      this.dataConfigurationHandlingService.createNewNodeToRawData(
        configData,
        action.treeNode
      )
    }
    this.dataConfigurationHandlingService.reorderDataConfiguration(
      configData,
      action.treeNode,
      action.newIndex
    )
    patchState({
      dataConfiguration: configData,
      isConfigurationChanged: true,
    })
  }

  @Action(SetErrorInformation)
  setErrorInformation(
    { patchState, getState }: StateContext<AppStateModel>,
    action: SetErrorInformation
  ) {
    patchState({
      error: {
        ...getState().error,
        errorInformation: action.errorInformation,
      },
    })
  }

  @Action(SetErrorType)
  setErrorType(
    { patchState, getState }: StateContext<AppStateModel>,
    action: SetErrorType
  ) {
    patchState({
      error: {
        ...getState().error,
        isHttpError: action.httpError,
      },
    })
  }

  @Action(SetUserProfileId)
  setUserProfileId(
    { patchState }: StateContext<AppStateModel>,
    action: SetUserProfileId
  ) {
    patchState({
      zonarOwnerId: action.profileId,
    })
  }

  @Action(SaveConfiguration)
  saveConfiguration({ getState, patchState }: StateContext<AppStateModel>) {
    const configurationId = getState().configurationId
    const configurationObject = this.prepareConfigurationObjectForUpdate(
      getState(),
      true,
      false
    )
    const companyId = getState().companyId
    const translationObject = cloneDeep(getState().translationObject)

    return this.companiesService
      .updateConfiguration(
        configurationId,
        configurationObject,
        companyId,
        false,
        translationObject
      )
      .pipe(
        tap(() => {
          patchState({
            isWorkInProgress: false,
            isConfigurationChanged: false,
            isUndoable: false,
            ect2Data: configurationObject.ect2Data,
            created: configurationObject.created,
          })
        })
      )
  }

  @Action(PublishConfiguration)
  publishConfiguration({ getState, patchState, dispatch }: StateContext<AppStateModel>) {
    const configurationId = getState().configurationId
    const configurationObject = this.prepareConfigurationObjectForUpdate(
      getState(),
      false,
      false
    )
    const companyId = getState().companyId

    return this.companiesService
      .updateConfiguration(configurationId, configurationObject, companyId, true)
      .pipe(
        tap(() => {
          patchState({
            isConfigurationChanged: false,
            isUndoable: false,
            ect2Data: configurationObject.ect2Data,
            created: configurationObject.created,
          })

          dispatch(new UpdateLanguageDictionary())
        })
      )
  }

  @Action(TestConfiguration)
  testConfiguration({ getState, patchState, dispatch }: StateContext<AppStateModel>) {
    const configurationId = getState().configurationId
    const configurationObject = this.prepareConfigurationObjectForUpdate(
      getState(),
      false,
      true
    )
    const companyId = getState().companyId

    return this.companiesService
      .updateConfiguration(configurationId, configurationObject, companyId, true)
      .pipe(
        tap(() => {
          patchState({
            isWorkInProgress: true,
            isConfigurationChanged: false,
            isUndoable: false,
            ect2Data: configurationObject.ect2Data,
            created: configurationObject.created,
          })

          dispatch(new UpdateLanguageDictionary())
        })
      )
  }

  private prepareExportConfigurationData(
    node: TreeFlatNode,
    companyName: string,
    legacyCodes: string[],
    dataConfiguration?: ZoneLayoutModel[],
    dataLanguageDictionary?: EvirLanguageDictionary,
    dataPiece?: ConfigurationPieceModel
  ): InstallerExportModel {
    let configName: string
    let configData

    if (node) {
      configName = node.name
      configData = dataPiece
        ? this.exportDataConfigurationService.convertPieceDataToExport(dataPiece)
        : this.exportDataConfigurationService.convertInstallerExportOneData(
            dataConfiguration,
            dataLanguageDictionary,
            node
          )
    } else {
      configName = this.translateService.instant('EXPORT_TEMPLATE.CONFIG_PACKAGE')
      configData = this.exportDataConfigurationService.convertInstallerExportAllData(
        dataConfiguration,
        dataLanguageDictionary
      )
    }

    configData = {
      ...configData,
      evirMobileHeaderLabel: this.translateService.instant(
        'EXPORT_TEMPLATE.EVIR_MOBILE_HEADER'
      ),
      companyLabel: this.translateService.instant('COMPANY').toUpperCase(),
      exportedDateLabel: this.translateService
        .instant('EXPORT_TEMPLATE.EXPORTED')
        .toUpperCase(),
      lastUpdatedLabel: this.translateService
        .instant('EXPORT_TEMPLATE.LAST_UPDATED')
        .toUpperCase(),
      versionLabel: this.translateService
        .instant('EXPORT_TEMPLATE.VERSION')
        .toUpperCase(),
      accountCodeLabel: this.translateService
        .instant('EXPORT_TEMPLATE.ACCOUNT_CODE')
        .toUpperCase(),
      configNameLabel: this.translateService
        .instant('EXPORT_TEMPLATE.CONFIG_NAME')
        .toUpperCase(),
      assetTypesLabel: this.translateService
        .instant('EXPORT_TEMPLATE.ASSET_TYPES')
        .toUpperCase(),
      zoneNameLabel: this.translateService
        .instant('EXPORT_TEMPLATE.ZONE_NAME')
        .toUpperCase(),
      zoneNumberLabel: this.translateService
        .instant('EXPORT_TEMPLATE.ZONE_NUMBER')
        .toUpperCase(),
      componentNameLabel: this.translateService
        .instant('EXPORT_TEMPLATE.COMPONENT_NAME')
        .toUpperCase(),
      conditionNameLabel: this.translateService
        .instant('EXPORT_TEMPLATE.CONDITION_NAME')
        .toUpperCase(),
    }

    configData = {
      ...configData,
      companyName: companyName,
      configName: configName,
      date: this.timeFormatService.installerExportDateFormat(new Date()),
      code: legacyCodes && legacyCodes.length ? legacyCodes[0] : null,
    } as InstallerExportModel
    return configData
  }

  @Action(InstallerExportDataConfiguration)
  installerExportDataConfiguration(
    { getState }: StateContext<AppStateModel>,
    action: InstallerExportDataConfiguration
  ) {
    let exportConfigData
    if (action.isExportPiece) {
      const dataPiece = action.node.value
      exportConfigData = this.prepareExportConfigurationData(
        action.node,
        this.translateService.instant('EXPORT_TEMPLATE.NO_COMPANY'),
        null,
        null,
        null,
        dataPiece
      )
    } else {
      const dataConfiguration = cloneDeep(getState().dataConfiguration)
      const dataLanguageDictionary = cloneDeep(AppState.getLanguageDictionary(getState()))
      const companyName = getState().companyContext.name
      const legacyCodes = getState().selectedCompanyLegacy
      exportConfigData = this.prepareExportConfigurationData(
        action.node,
        companyName,
        legacyCodes,
        dataConfiguration,
        dataLanguageDictionary
      )
    }
    this.exportDataConfigurationService.generateDocxFile(
      exportConfigData,
      action.installerExportType
    )
  }

  @Action(GetLegacyAccountCode)
  getLegacyAccountCode(
    { patchState }: StateContext<AppStateModel>,
    action: GetLegacyAccountCode
  ) {
    return this.companiesService.getLegacyAccountCode(action.companyId).pipe(
      tap((result: any) => {
        patchState({
          selectedCompanyLegacy: result,
        })
      })
    )
  }

  @Action(CloneDataConfiguration)
  cloneDataConfiguration(
    { getState, patchState }: StateContext<AppStateModel>,
    action: CloneDataConfiguration
  ) {
    const configuration: ZoneLayoutModel[] = cloneDeep(getState().dataConfiguration)
    const selectedConfig = cloneDeep(getState().selectedConfiguration)
    this.dataConfigurationHandlingService.cloneDataConfiguration(
      configuration,
      action.node,
      selectedConfig
    )
    patchState({
      dataConfiguration: configuration,
      selectedConfiguration: selectedConfig,
      isConfigurationChanged: true,
    })
  }

  @Action(SetExpandedStates)
  setExpandedStates(
    { patchState }: StateContext<AppStateModel>,
    action: SetExpandedStates
  ) {
    patchState({
      expandedStates: action.expandedStates,
    })
  }

  @Action(SelectConfiguration)
  selectConfiguration(
    { patchState, getState }: StateContext<AppStateModel>,
    action: SelectConfiguration
  ) {
    if (!action.node) {
      patchState({
        selectedConfiguration: isValidArray(getState().dataConfiguration)
          ? { index: 0 }
          : null,
      })
      return
    }

    const parentsIndex: number[] = action.node.id.split('/').map((x) => Number(x))
    const configIndex = parentsIndex[NODE_LEVELS_INDEX.CONFIG_LEVEL]
    const selectedConfiguration = {
      index: configIndex,
    } as SelectedConfigurationModel

    patchState({
      selectedConfiguration: selectedConfiguration,
    })

    const assetViewId = action.node.value.assetViewId
    const companyId = getState().companyId
    return this.store.dispatch(new GetAssetZoneMapBackground(companyId, assetViewId))
  }

  @Action(GetAssetZoneMapBackground)
  getAssetZoneMapBackground(
    { patchState }: StateContext<AppStateModel>,
    action: GetAssetZoneMapBackground
  ) {
    patchState({
      assetViewBackground: null,
    })

    return this.companiesService
      .getAssetViewBackground(action.companyId, action.assetViewId)
      .pipe(
        catchError((error) => {
          return of('')
        }),
        tap((result: any) => {
          patchState({
            assetViewBackground: result,
          })
        })
      )
  }

  @Action(UpdateAssetZoneMapPosition)
  updateAssetZoneMapPosition(
    { patchState, getState }: StateContext<AppStateModel>,
    action: UpdateAssetZoneMapPosition
  ) {
    const configIndex = getState().selectedConfiguration.index
    const dataConfiguration = cloneDeep(getState().dataConfiguration)

    const updateConfigData = dataConfiguration[configIndex]
    const updateZoneData = updateConfigData.configZones.find((zone) => {
      const positionX = zone.assetViewLocation[GRID_VIEW_POSITION_INDEX.POSITION_X]
      const positionY = zone.assetViewLocation[GRID_VIEW_POSITION_INDEX.POSITION_Y]
      return (
        positionX === action.positionValue.oldValue.positionX &&
        positionY === action.positionValue.oldValue.positionY
      )
    })

    updateZoneData.assetViewLocation = [
      action.positionValue.newValue.positionX,
      action.positionValue.newValue.positionY,
    ]

    patchState({
      dataConfiguration: dataConfiguration,
      isConfigurationChanged: true,
    })
  }

  @Action(HoverAssetZoneMap)
  hoverAssetZoneMap(
    { patchState }: StateContext<AppStateModel>,
    action: HoverAssetZoneMap
  ) {
    patchState({
      hoveringAssetZoneBox: action.zoneBox,
    })
  }

  @Action(SetWorkInProgressStatus)
  setWorkInProgressStatus(
    { patchState }: StateContext<AppStateModel>,
    action: SetWorkInProgressStatus
  ) {
    patchState({
      isWorkInProgress: action.isWorkInProgress,
    })
  }

  @Action(SetDraftStatus)
  setDraftStatus({ patchState }: StateContext<AppStateModel>, action: SetDraftStatus) {
    patchState({
      draft: action.draft,
    })
  }

  @Action(EnableUndoFeature)
  enableUndoFeature({ patchState }: StateContext<AppStateModel>) {
    patchState({
      isUndoable: true,
    })
  }

  @Action(DisableUndoFeature)
  disableUndoFeature({ patchState }: StateContext<AppStateModel>) {
    patchState({
      isUndoable: false,
    })
  }

  @Action(SetAccessStatus)
  setAccessStatus({ patchState }: StateContext<AppStateModel>, action: SetAccessStatus) {
    patchState({
      isAccessGranted: !!action.isAccessGranted,
    })
  }

  @Action(UndoConfigurationChange)
  undoConfigurationChange({ patchState }: StateContext<AppStateModel>) {
    patchState({
      isActiveUndo: true,
      hoveringAssetZoneBox: null,
    })
  }

  @Action(DisableUndoConfigurationChange)
  disableUndoConfigurationChange({ patchState }: StateContext<AppStateModel>) {
    patchState({
      isActiveUndo: false,
    })
  }

  @Action(CopyConfiguration)
  copyConfiguration(
    { patchState, getState }: StateContext<AppStateModel>,
    action: CopyConfiguration
  ) {
    if (action.node === null) {
      patchState({
        copiedValue: null,
      })
      return
    }

    const configuration: ZoneLayoutModel[] = cloneDeep(getState().dataConfiguration)
    const dataInspectionDetails: InspectionDetailModel[] = cloneDeep(
      getState().dataInspectionDetails
    )
    const evirLanguageDictionary: EvirLanguageDictionary = AppState.getLanguageDictionary(
      getState()
    )

    const nodeCopiedData: ZoneLayoutModel =
      this.importDataConfigurationService.copyConfiguration(configuration, action.node)
    const copiedInspectionByNameMapping: Record<string, InspectionDetailModel> =
      this.importDataConfigurationService.collectInspectionsBelongToAnAsset(
        action.node,
        nodeCopiedData,
        dataInspectionDetails,
        evirLanguageDictionary
      )
    const copiedInspectionTypes: InspectionDetailModel[] = Object.values(
      copiedInspectionByNameMapping
    )

    patchState({
      copiedValue: {
        copiedCompanyId: getState().companyId,
        originalCopyNodeId: action.node.id,
        nodeCopiedData,
        copiedInspectionByNameMapping,
        nodeCopyEvirLanguageDictionary:
          this.importDataConfigurationService.buildEvirLangDictionaryForConfiguration(
            nodeCopiedData,
            copiedInspectionTypes,
            evirLanguageDictionary
          ),
      },
    })
  }

  @Action(PasteConfiguration)
  pasteConfiguration(
    { patchState, getState }: StateContext<AppStateModel>,
    action: PasteConfiguration
  ) {
    try {
      let {
        companyId,
        copiedValue: {
          copiedCompanyId,
          copiedInspectionByNameMapping,
          nodeCopiedData,
          nodeCopyEvirLanguageDictionary,
          originalCopyNodeId,
        },
        dataConfiguration,
        dataInspectionDetails,
        evirLanguageDictionary,
        selectedConfiguration,
        translationObject,
      } = cloneDeep(getState())

      let inspectionTypeNames = AppState.getInspectionTypeNames({
        dataInspectionDetails,
        evirLanguageDictionary,
        translationObject,
      })
      const copiedInspectionTypeNames = Object.keys(copiedInspectionByNameMapping)
      const missingInspectionTypes =
        this.dataConfigurationHandlingService.getMissingInspectionTypes(
          evirLanguageDictionary,
          inspectionTypeNames,
          copiedInspectionTypeNames,
          nodeCopiedData,
          originalCopyNodeId
        )

      if (missingInspectionTypes.length) {
        const newInspectionTypeList =
          this.importDataConfigurationService.createMissingInspectionTypeList(
            missingInspectionTypes,
            copiedInspectionByNameMapping,
            copiedCompanyId,
            companyId,
            translationObject,
            evirLanguageDictionary,
            nodeCopyEvirLanguageDictionary
          )

        // Inserts new inspections at the start of existing inspections
        dataInspectionDetails = [...newInspectionTypeList, ...dataInspectionDetails]
        inspectionTypeNames = [...missingInspectionTypes, ...inspectionTypeNames]
      }
      if (companyId !== copiedCompanyId) {
        this.importDataConfigurationService.pasteConfigurationToOtherCompany(
          dataConfiguration,
          action.node,
          nodeCopiedData,
          originalCopyNodeId,
          selectedConfiguration,
          translationObject,
          evirLanguageDictionary,
          nodeCopyEvirLanguageDictionary,
          inspectionTypeNames
        )
      } else {
        this.importDataConfigurationService.pasteConfiguration(
          evirLanguageDictionary,
          dataConfiguration,
          action.node,
          nodeCopiedData,
          originalCopyNodeId,
          selectedConfiguration,
          inspectionTypeNames
        )
      }

      patchState({
        selectedConfiguration,
        dataConfiguration,
        dataInspectionDetails,
        isConfigurationChanged: true,
        translationObject,
      })
    } catch (error) {
      if (
        error instanceof UndefinedOrNullLangValueError ||
        error instanceof UndefinedOrNullUUIDValueError
      ) {
        console.error(error)
      } else {
        throw error
      }
    }
  }

  @Action(ImportLegacyConfig)
  importLegacyConfig(
    { patchState, getState }: StateContext<AppStateModel>,
    action: ImportLegacyConfig
  ) {
    try {
      let dataConfiguration: ZoneLayoutModel[] = cloneDeep(getState().dataConfiguration)
      const translationObject: TranslationObject = cloneDeep(getState().translationObject)
      const evirLanguageDictionary: EvirLanguageDictionary = cloneDeep(
        getState().evirLanguageDictionary
      )
      let selectedConfig = cloneDeep(getState().selectedConfiguration)
      const oldConfigLength = dataConfiguration.length

      action.legacyConfigXmlList.forEach((legacyConfigXml) => {
        dataConfiguration = this.xmlConverterService.convertLegacyXMLToConfig(
          legacyConfigXml,
          dataConfiguration,
          translationObject,
          evirLanguageDictionary
        )
      })

      const numberNewConfig = dataConfiguration.length - oldConfigLength
      selectedConfig.index = selectedConfig.index + numberNewConfig

      patchState({
        dataConfiguration: dataConfiguration,
        translationObject: translationObject,
        selectedConfiguration: selectedConfig,
        isConfigurationChanged: true,
      })
    } catch (error) {
      if (error instanceof UndefinedOrNullLangValueError) {
        console.error(error)
      } else {
        throw error
      }
    }
  }

  @Action(DeleteBorderDataConfigurationImport)
  deleteBorderDataConfigurationImport({
    patchState,
    getState,
  }: StateContext<AppStateModel>) {
    const configData = cloneDeep(getState().dataConfiguration)
    this.dataConfigurationHandlingService.deleteBorderDataConfigImport(configData)

    patchState({
      dataConfiguration: configData,
    })
  }

  @Action(SetFilterInspectionTypes)
  setFilterInspectionTypes(
    { patchState, getState }: StateContext<AppStateModel>,
    action: SetFilterInspectionTypes
  ) {
    if (isEqual(getState().filterInspectionTypes, action.inspectionTypes)) {
      return
    }

    patchState({
      filterInspectionTypes: action.inspectionTypes,
    })
  }

  @Action(SetConfigurationChangedStatus)
  setConfigurationChangedStatus(
    { patchState }: StateContext<AppStateModel>,
    action: SetConfigurationChangedStatus
  ) {
    patchState({
      isConfigurationChanged: action.isConfigurationChanged,
    })
  }

  @Action(GetAssetViewList)
  getAssetViewList(
    { patchState }: StateContext<AppStateModel>,
    action: GetAssetViewList
  ) {
    return this.companiesService.getAllAssetView(action.companyId).pipe(
      tap((assetViewList: AssetViewModel[]) => {
        patchState({
          assetViewList: assetViewList,
        })
      })
    )
  }

  @Action(UploadAssetMapView)
  uploadAssetMapView(
    { patchState, getState }: StateContext<AppStateModel>,
    action: UploadAssetMapView
  ) {
    return this.companiesService.uploadAssetViewBackground(action.fileUpload).pipe(
      tap((response: AssetViewUploadModel) => {
        const selectedCompanyId = getState().companyId

        patchState({
          updateAssetViewMapResponse: response,
        })

        return this.store.dispatch(new GetAssetViewList(selectedCompanyId))
      })
    )
  }

  @Action(SelectInspectionType)
  selectInspectionType(
    { patchState }: StateContext<AppStateModel>,
    action: SelectInspectionType
  ) {
    patchState({
      selectedInspectionTypeIndex: action.index,
    })
  }

  @Action(RemoveInspectionTypeDetailRow)
  removeInspectionTypeDetailRow(
    { patchState, getState }: StateContext<AppStateModel>,
    action: RemoveInspectionTypeDetailRow
  ) {
    const inspectionDetails = cloneDeep(getState().dataInspectionDetails)
    const selectedIndex = getState().selectedInspectionTypeIndex
    const selectedInspectionDetails = inspectionDetails[selectedIndex]

    inspectionDetails[selectedIndex] = {
      ...selectedInspectionDetails,
      formData: selectedInspectionDetails.formData.filter(
        (_, index) => index !== action.index
      ), // immutable removal
    }

    if (!isValidArray(inspectionDetails[selectedIndex].formData)) {
      delete inspectionDetails[selectedIndex].formData
    }

    patchState({
      dataInspectionDetails: inspectionDetails,
      isConfigurationChanged: true,
    })
  }

  @Action(GetTemplateLibraryPieces)
  getTemplateLibraryPieces({ patchState, getState }: StateContext<AppStateModel>) {
    return this.companiesService.getTemplateLibraryPieces(getState().companyId).pipe(
      filter(
        (templateLibraryPieces) =>
          !!(templateLibraryPieces && templateLibraryPieces.length)
      ),
      tap((result) => {
        patchState({
          templateLibraryPieces: result,
        })
      })
    )
  }

  @Action(UpdateFilterPieceType)
  updateFilterPieceType(
    { patchState }: StateContext<AppStateModel>,
    action: UpdateFilterPieceType
  ) {
    patchState({
      filterPieceType: action.newFilterPieceType,
    })
  }

  @Action(UpdatePieceSortOption)
  updatePieceSortOption(
    { patchState }: StateContext<AppStateModel>,
    action: UpdatePieceSortOption
  ) {
    patchState({
      pieceSortOption: action.newPieceSortOption,
    })
  }

  @Action(UpdatePieceSearchText)
  updatePieceSearchText(
    { patchState }: StateContext<AppStateModel>,
    action: UpdatePieceSearchText
  ) {
    patchState({
      pieceSearchText: action.newSearchText,
    })
  }

  @Action(DeleteTemplateLibraryPiece)
  @Action(DeleteFormLibraryPiece)
  deletePiece(
    _: StateContext<AppStateModel>,
    action: DeleteTemplateLibraryPiece | DeleteFormLibraryPiece
  ) {
    return this.companiesService.deleteTemplateLibraryPiece(action.pieceId)
  }

  @Action(UpdatePiece)
  updatePiece(_: StateContext<AppStateModel>, action: UpdatePiece) {
    return this.companiesService.updateTemplateLibraryPiece(
      action.id,
      action.updateData,
      action.isAdmin
    )
  }

  @Action(GetBlankPieces)
  getBlankPieces({ patchState, getState }: StateContext<AppStateModel>) {
    const translationObject = cloneDeep(getState().translationObject)
    const evirLanguageDictionary = cloneDeep(getState().evirLanguageDictionary)

    return this.companiesService
      .getBlankPieces(
        this.langDictionaryService,
        evirLanguageDictionary,
        translationObject
      )
      .pipe(
        tap((result) => {
          patchState({
            blankPieces: result,
          })
        })
      )
  }

  @Action(AddTemplateLibraryPiece)
  addTemplateLibraryPiece(
    { getState }: StateContext<AppStateModel>,
    action: AddTemplateLibraryPiece
  ) {
    const configurationList = cloneDeep(getState().dataConfiguration)
    const mergedLanguageDictionary = cloneDeep(AppState.getLanguageDictionary(getState()))
    let newPiece = this.pieceConvertingService.convertToTemplateLibraryPiece(
      configurationList,
      mergedLanguageDictionary,
      action.node,
      action.isIncludedAllSublayers
    ) as RawTemplateLibraryPieceModel
    newPiece = {
      ...newPiece,
      name: trimAll(action.pieceName),
    }
    return this.companiesService.addTemplateLibraryPiece(newPiece, action.isAdminViewOnly)
  }

  @Action(PasteFromTemplateLibrary)
  pasteFromTemplateLibrary(
    { patchState, getState }: StateContext<AppStateModel>,
    action: PasteFromTemplateLibrary
  ) {
    try {
      const configuration: ZoneLayoutModel[] = cloneDeep(getState().dataConfiguration)
      const selectedConfiguration = cloneDeep(getState().selectedConfiguration)
      const translationObject = cloneDeep(getState().translationObject)
      const evirLanguageDictionary = cloneDeep(getState().evirLanguageDictionary)
      const currentInspectionTypeNames = AppState.getInspectionTypeNames(getState())

      this.importDataConfigurationService.parseFromTemplateLibrary(
        action.node,
        action.insertId,
        configuration,
        translationObject,
        evirLanguageDictionary,
        selectedConfiguration,
        action.includeSubLayers,
        action.insertAtLast,
        currentInspectionTypeNames
      )

      patchState({
        selectedConfiguration: selectedConfiguration,
        dataConfiguration: configuration,
        isConfigurationChanged: true,
        translationObject: translationObject,
      })
    } catch (error) {
      if (error instanceof UndefinedOrNullLangValueError) {
        console.error(error)
      } else {
        throw error
      }
    }
  }

  @Action(DeleteInspectionDetail)
  deleteInspectionDetail(_: StateContext<AppStateModel>, action: DeleteInspectionDetail) {
    const inspectionsDetail = cloneDeep(_.getState().dataInspectionDetails) as any[]
    let selectedInspectionTypeIndex = _.getState().selectedInspectionTypeIndex

    remove(inspectionsDetail, (item, index) => index === action.index)
    if (inspectionsDetail.length) {
      if (selectedInspectionTypeIndex === action.index) {
        selectedInspectionTypeIndex = 0
      } else if (selectedInspectionTypeIndex > action.index) {
        selectedInspectionTypeIndex = selectedInspectionTypeIndex - 1
      }
    } else {
      selectedInspectionTypeIndex = null
    }

    _.patchState({
      dataInspectionDetails: inspectionsDetail,
      selectedInspectionTypeIndex: selectedInspectionTypeIndex,
      isConfigurationChanged: true,
    })
  }

  @Action(SetUserEmail)
  setUserEmail({ patchState }: StateContext<AppStateModel>, action: SetUserEmail) {
    patchState({
      userEmail: action.userEmail,
    })
  }

  @Action(UpdateInspectionFormData)
  updateInspectionFormData(
    { patchState, getState, dispatch }: StateContext<AppStateModel>,
    action: UpdateInspectionFormData
  ) {
    try {
      const inspectionDetails = cloneDeep(getState().dataInspectionDetails)
      const translationObject = cloneDeep(getState().translationObject)
      const evirLanguageDictionary = cloneDeep(getState().evirLanguageDictionary)

      inspectionDetails[action.inspectionIndex].formData[action.formDataIndex] =
        this.createNewFormDataItem(
          action.payload,
          translationObject,
          evirLanguageDictionary
        )

      patchState({
        dataInspectionDetails: inspectionDetails,
        translationObject: translationObject,
      })

      dispatch(new SetConfigurationChangedStatus(true))
    } catch (error) {
      if (error instanceof UndefinedOrNullLangValueError) {
        console.error(error)
      } else {
        throw error
      }
    }
  }

  @Action(DropToInspectionTypeDetail)
  dropToInspectionTypeDetail(
    { patchState, getState, dispatch }: StateContext<AppStateModel>,
    action: DropToInspectionTypeDetail
  ) {
    try {
      const inspectionDetails = cloneDeep(getState().dataInspectionDetails)
      const translationObject = cloneDeep(getState().translationObject)
      const evirLanguageDictionary = cloneDeep(getState().evirLanguageDictionary)

      const newRowData = this.createNewFormDataItem(
        action.payload,
        translationObject,
        evirLanguageDictionary
      )
      if (isValidArray(inspectionDetails[action.inspectionIndex].formData)) {
        inspectionDetails[action.inspectionIndex].formData.splice(
          action.formDataIndex,
          0,
          newRowData
        )
      } else {
        inspectionDetails[action.inspectionIndex].formData = [newRowData]
      }

      patchState({
        dataInspectionDetails: inspectionDetails,
        translationObject: translationObject,
      })

      dispatch(new SetConfigurationChangedStatus(true))
    } catch (error) {
      if (error instanceof UndefinedOrNullLangValueError) {
        console.error(error)
      } else {
        throw error
      }
    }
  }

  @Action(AddInspectionType)
  addInspectionType(
    { patchState, getState, dispatch }: StateContext<AppStateModel>,
    action: AddInspectionType
  ) {
    try {
      const inspectionDetails = cloneDeep(getState().dataInspectionDetails)
      const translationObject = cloneDeep(getState().translationObject)
      const evirLanguageDictionary = cloneDeep(getState().evirLanguageDictionary)

      const {
        inspectionDetailLanguageKey,
        inspectionDetailLangKey,
        inspectionDescriptionLanguageKey,
        inspectionDescriptionLangKey,
        formData,
        ...rest
      } = action.rawInspectionType
      const newFormData = isValidArray(formData)
        ? {
            formData: formData.map(
              (formValue) =>
                this.createNewFormDataItem(
                  transformToInspectionTypeFormData(formValue),
                  translationObject,
                  evirLanguageDictionary
                ),
              LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH]
            ),
          }
        : {}
      const newInspectionType = {
        ...rest,
        inspectionDetailLangKey:
          inspectionDetailLangKey ||
          this.langDictionaryService.getOrCreateLangKey(
            inspectionDetailLanguageKey,
            translationObject,
            evirLanguageDictionary,
            LANGUAGE_CONFIG_TYPES.INSPECTION_TYPE,
            LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH]
          ),
        inspectionDescriptionLangKey:
          inspectionDescriptionLangKey ||
          this.langDictionaryService.getOrCreateLangKey(
            inspectionDescriptionLanguageKey,
            translationObject,
            evirLanguageDictionary,
            LANGUAGE_CONFIG_TYPES.INSPECTION_TYPE,
            LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH]
          ),
        ...newFormData,
      }

      inspectionDetails.push(newInspectionType)

      patchState({
        dataInspectionDetails: inspectionDetails,
        translationObject: translationObject,
      })

      dispatch(new SetConfigurationChangedStatus(true))
    } catch (error) {
      if (error instanceof UndefinedOrNullLangValueError) {
        console.error(error)
      } else {
        throw error
      }
    }
  }

  @Action(CopyInspectionTypeDetailRow)
  copyInspectionTypeDetailRow(
    { getState, patchState }: StateContext<AppStateModel>,
    action: CopyInspectionTypeDetailRow
  ) {
    const mergedEvirLanguageDictionary = AppState.getLanguageDictionary(getState())
    const { fieldNameLabel, hint, select, ...rest } = action.inspectionTypeDetailRow

    const copiedRow: CopiedInspectionTypeDetailRowModel = {
      companyId: getState().companyId,
      rowData: rest,
      evirLanguageDictionary:
        this.importDataConfigurationService.buildEvirLangDictionaryForCopiedInspection(
          null,
          rest,
          mergedEvirLanguageDictionary
        ),
    }

    patchState({
      copiedInspectionDetailRow: copiedRow,
    })
  }

  @Action(PasteInspectionTypeDetailRow)
  pasteInspectionTypeDetailRow({
    patchState,
    getState,
    dispatch,
  }: StateContext<AppStateModel>) {
    const createPastedRow = (
      copiedRow: CopiedInspectionTypeDetailRowModel,
      currentCompanyId: string,
      translationObject: TranslationObject,
      evirLanguageDictionary: EvirLanguageDictionary,
      evirLangDictionaryCopy: EvirLanguageDictionary
    ) => {
      if (copiedRow.companyId === currentCompanyId) {
        return copiedRow.rowData
      }

      const pastedRow = {
        ...copiedRow.rowData,
        fieldNameLangKey: this.importDataConfigurationService.handleRebuildLangString(
          copiedRow.rowData.fieldNameLangKey,
          translationObject,
          evirLanguageDictionary,
          evirLangDictionaryCopy,
          LANGUAGE_CONFIG_TYPES.FORM_DATA
        ),
        hintLangKey: this.importDataConfigurationService.handleRebuildLangString(
          copiedRow.rowData.hintLangKey,
          translationObject,
          evirLanguageDictionary,
          evirLangDictionaryCopy,
          LANGUAGE_CONFIG_TYPES.FORM_DATA
        ),
      }
      if (pastedRow.selectLangKeys) {
        pastedRow.selectLangKeys = pastedRow.selectLangKeys.map((value) =>
          this.importDataConfigurationService.handleRebuildLangString(
            value,
            translationObject,
            evirLanguageDictionary,
            evirLangDictionaryCopy,
            LANGUAGE_CONFIG_TYPES.FORM_DATA
          )
        )
      }
      return pastedRow
    }

    try {
      const inspectionDetails = cloneDeep(getState().dataInspectionDetails)
      const copiedInspectionDetailRow = cloneDeep(getState().copiedInspectionDetailRow)
      const companyId = getState().companyId
      const selectedInspectionTypeIndex = getState().selectedInspectionTypeIndex
      const translationObject = cloneDeep(getState().translationObject)
      const evirLanguageDictionary = cloneDeep(getState().evirLanguageDictionary)

      inspectionDetails[selectedInspectionTypeIndex].formData = [
        ...[
          createPastedRow(
            copiedInspectionDetailRow,
            companyId,
            translationObject,
            evirLanguageDictionary,
            copiedInspectionDetailRow.evirLanguageDictionary
          ),
        ],
        ...(inspectionDetails[selectedInspectionTypeIndex].formData || []),
      ]

      patchState({
        dataInspectionDetails: inspectionDetails,
        translationObject: translationObject,
      })

      dispatch(new SetConfigurationChangedStatus(true))
    } catch (error) {
      if (
        error instanceof UndefinedOrNullLangValueError ||
        error instanceof UndefinedOrNullUUIDValueError
      ) {
        console.error(error)
      } else {
        throw error
      }
    }
  }

  @Action(UpdateInspectionType)
  updateInspectionType(
    { patchState, getState, dispatch }: StateContext<AppStateModel>,
    action: UpdateInspectionType
  ) {
    try {
      const inspectionDetails = cloneDeep(getState().dataInspectionDetails)
      const translationObject = cloneDeep(getState().translationObject)
      const evirLanguageDictionary = cloneDeep(getState().evirLanguageDictionary)
      const mergedTranslationObject = cloneDeep(
        AppState.getLanguageDictionary(getState())
      )

      const oldInspectionTypeLabel = this.langDictionaryService.convertLangKeyToString(
        inspectionDetails[action.inspectionIndex].inspectionDetailLangKey,
        mergedTranslationObject
      )

      const inspectionTypeName = trimAll(action.data.label)
      const labelNameLangKey = this.langDictionaryService.getOrCreateLangKey(
        inspectionTypeName,
        translationObject,
        evirLanguageDictionary
      )
      const descriptionNameLangKey = this.langDictionaryService.getOrCreateLangKey(
        action.data.description,
        translationObject,
        evirLanguageDictionary
      )

      inspectionDetails[action.inspectionIndex] = {
        ...inspectionDetails[action.inspectionIndex],
        inspectionDetailLangKey: labelNameLangKey,
        inspectionDescriptionLangKey: descriptionNameLangKey,
        inspectionType: action.data.category,
      }

      patchState({
        dataInspectionDetails: inspectionDetails,
        translationObject: translationObject,
      })

      if (oldInspectionTypeLabel !== inspectionTypeName) {
        dispatch(new UpdateZoneInspectionType(oldInspectionTypeLabel, inspectionTypeName))
      }

      dispatch(new SetConfigurationChangedStatus(true))
    } catch (error) {
      if (error instanceof UndefinedOrNullLangValueError) {
        console.error(error)
      } else {
        throw error
      }
    }
  }

  @Action(ToggleInspectionAdditionalInformation)
  toggleInspectionAdditionalInformation(
    { patchState, getState, dispatch }: StateContext<AppStateModel>,
    action: ToggleInspectionAdditionalInformation
  ) {
    const inspectionDetails = cloneDeep(getState().dataInspectionDetails)
    const currentValue = !!inspectionDetails[action.inspectionIndex][action.key]
    inspectionDetails[action.inspectionIndex][action.key] = !currentValue
    patchState({
      dataInspectionDetails: inspectionDetails,
    })

    dispatch(new SetConfigurationChangedStatus(true))
  }

  @Action(CopyInspectionType)
  copyInspectionType(
    { getState, patchState }: StateContext<AppStateModel>,
    action: CopyInspectionType
  ) {
    const inspectionDetails = cloneDeep(getState().dataInspectionDetails)
    const mergedLanguageDictionary = AppState.getLanguageDictionary(getState())

    const copiedInspectionType: CopiedInspectionTypeModel = {
      companyId: getState().companyId,
      inspectionTypeName: action.inspectionType.name,
      inspectionTypeData: inspectionDetails[action.inspectionTypeIndex],
      evirLanguageDictionary:
        this.importDataConfigurationService.buildEvirLangDictionaryForCopiedInspection(
          inspectionDetails[action.inspectionTypeIndex],
          null,
          mergedLanguageDictionary
        ),
    }

    patchState({
      copiedInspectionType,
    })
  }

  @Action(PasteInspectionType)
  pasteInspectionType(
    { patchState, getState, dispatch }: StateContext<AppStateModel>,
    action: PasteInspectionType
  ) {
    try {
      const dataInspectionDetails = cloneDeep(getState().dataInspectionDetails)
      const translationObject = cloneDeep(getState().translationObject)
      const evirLangDictionaryCopy = cloneDeep(
        getState().copiedInspectionType.evirLanguageDictionary
      )

      dataInspectionDetails.splice(
        0,
        0,
        this.importDataConfigurationService.createPastedInspectionType(
          getState().copiedInspectionType,
          getState().companyId,
          action.newInspectionTypeName,
          translationObject,
          getState().evirLanguageDictionary,
          evirLangDictionaryCopy
        )
      )

      patchState({
        dataInspectionDetails,
        selectedInspectionTypeIndex: (getState().selectedInspectionTypeIndex || -1) + 1,
        translationObject,
      })

      dispatch(new SetConfigurationChangedStatus(true))
    } catch (error) {
      if (
        error instanceof UndefinedOrNullLangValueError ||
        error instanceof UndefinedOrNullUUIDValueError
      ) {
        console.error(error)
      } else {
        throw error
      }
    }
  }

  @Action(CloneBelowInspectionTypes)
  cloneBelowInspectionTypes(
    { getState, patchState }: StateContext<AppStateModel>,
    action: CloneBelowInspectionTypes
  ) {
    try {
      const inspectionTypes = cloneDeep(getState().dataInspectionDetails)
      const translationObject = cloneDeep(getState().translationObject)
      const evirLanguageDictionary = cloneDeep(getState().evirLanguageDictionary)

      const inspectionTypeName = trimAll(action.inspectionTypeName)

      const clonedInspectionType = {
        ...inspectionTypes[action.index],
        inspectionDetailLangKey: this.langDictionaryService.getOrCreateLangKey(
          inspectionTypeName,
          translationObject,
          evirLanguageDictionary
        ),
      }

      inspectionTypes.splice(action.index + 1, 0, clonedInspectionType)

      patchState({
        dataInspectionDetails: inspectionTypes,
        translationObject: translationObject,
        isConfigurationChanged: true,
      })
    } catch (error) {
      if (error instanceof UndefinedOrNullLangValueError) {
        console.error(error)
      } else {
        throw error
      }
    }
  }

  @Action(CreateNewInspectionType)
  createNewInspectionType(
    { patchState, getState, dispatch }: StateContext<AppStateModel>,
    action: CreateNewInspectionType
  ) {
    try {
      const inspectionDetails = cloneDeep(getState().dataInspectionDetails)
      const translationObject = cloneDeep(getState().translationObject)
      const evirTranslationObject = cloneDeep(getState().evirLanguageDictionary)

      const inspectionTypeName = trimAll(action.data.label)

      const labelNameLangKey = this.langDictionaryService.getOrCreateLangKey(
        inspectionTypeName,
        translationObject,
        evirTranslationObject
      )
      const descriptionNameLangKey = this.langDictionaryService.getOrCreateLangKey(
        action.data.description,
        translationObject,
        evirTranslationObject
      )

      inspectionDetails.push({
        ect2Data: { data: 'Object containing data not passed to the mobile apps.' },
        enforceOrder: false,
        inspectionDetailLangKey: labelNameLangKey,
        inspectionDescriptionLangKey: descriptionNameLangKey,
        inspectionType: action.data.category,
        legallyMandated: false,
        prepopulateDefects: true,
        requiresReview: false,
        associateDriver: true,
        zoneless: action.data.zoneless,
      })

      patchState({
        dataInspectionDetails: inspectionDetails,
        translationObject: translationObject,
      })

      dispatch(new SetConfigurationChangedStatus(true))
    } catch (error) {
      if (error instanceof UndefinedOrNullLangValueError) {
        console.error(error)
      } else {
        throw error
      }
    }
  }

  @Action(AddPremadeFieldToFormLibrary)
  addPremadeFieldToFormLibrary(
    { getState }: StateContext<AppStateModel>,
    action: AddPremadeFieldToFormLibrary
  ) {
    const { fieldNameLabel, hint, ...rest } = action.rawPremadeFieldData
    const premadeField = {
      name: trimAll(action.premadeFieldName),
      pieceType: TEMPLATE_TYPES_VALUE.FORM,
      piece: {
        ...rest,
        fieldNameLabelKey: fieldNameLabel,
        hintKey: hint,
      },
    }
    return this.companiesService.addTemplateLibraryPiece(
      premadeField,
      action.isAdminViewOnly
    )
  }

  @Action(SetActiveCompanyId)
  setActiveCompanyId(
    { patchState }: StateContext<AppStateModel>,
    action: SetActiveCompanyId
  ) {
    patchState({
      activeCompanyId: action.activeCompanyId,
    })
  }

  @Action(UpdateZoneInspectionType)
  updateZoneInspectionType(
    { getState, patchState }: StateContext<AppStateModel>,
    action: UpdateZoneInspectionType
  ) {
    const evirLanguageDictionary = cloneDeep(AppState.getLanguageDictionary(getState()))
    const configurationData = cloneDeep(getState().dataConfiguration)
    this.dataConfigurationHandlingService.updateZoneInspectionType(
      configurationData,
      action.oldZoneInspectionType,
      action.newZoneInspectionType,
      evirLanguageDictionary
    )
    patchState({
      dataConfiguration: configurationData,
    })
  }

  @Action(UpdateLanguageDictionary)
  updateLanguageDictionary(
    { getState, patchState }: StateContext<AppStateModel>,
    action: UpdateLanguageDictionary
  ) {
    const translationObject = getState().translationObject

    if (Object.keys(translationObject || {}).length === 0) {
      return
    }

    const evirLanguageDictionary = cloneDeep(AppState.getLanguageDictionary(getState()))
    patchState({
      translationObject: {},
      evirLanguageDictionary: evirLanguageDictionary,
    })
  }

  private createNewFormDataItem(
    rawFormDataValue: InspectionTypeFormData,
    translationObject: TranslationObject,
    evirLangDictionary: EvirLanguageDictionary,
    langCode: string = LANGUAGE_CODE_BY_LANGUAGE_ID[
      LANGUAGE_IDS.ENGLISH
    ] /** TODO: Should change to use `currentLanguageCode` later */
  ): FormDataModel {
    const formatSelectField = (
      rawFormData: InspectionTypeFormData
    ): InspectionTypeFormData => {
      let { select, selectLangKeys, ...rest } = rawFormData

      if (isValidArray(select)) {
        selectLangKeys = select.map((option, index) => {
          return selectLangKeys && selectLangKeys[index]
            ? selectLangKeys[index]
            : this.langDictionaryService.getOrCreateLangKey(
                option,
                translationObject,
                evirLangDictionary,
                LANGUAGE_CONFIG_TYPES.FORM_DATA,
                LANGUAGE_CODE_BY_LANGUAGE_ID[LANGUAGE_IDS.ENGLISH]
              )
        })
      }
      return {
        ...rest,
        ...(selectLangKeys && { selectLangKeys }),
      }
    }

    const { fieldNameLabel, hint, fieldNameLangKey, hintLangKey, ...rest } =
      formatSelectField(rawFormDataValue)

    return {
      ...rest,
      fieldNameLangKey:
        fieldNameLangKey ||
        this.langDictionaryService.getOrCreateLangKey(
          fieldNameLabel,
          translationObject,
          evirLangDictionary,
          LANGUAGE_CONFIG_TYPES.FORM_DATA,
          langCode
        ),
      hintLangKey:
        hintLangKey ||
        this.langDictionaryService.getOrCreateLangKey(
          hint,
          translationObject,
          evirLangDictionary,
          LANGUAGE_CONFIG_TYPES.FORM_DATA,
          langCode
        ),
    }
  }

  private prepareConfigurationObjectForUpdate(
    state: AppStateModel,
    isSaved: boolean,
    isTested: boolean
  ): ConfigurationModel {
    return {
      zoneLayouts: state.dataConfiguration,
      inspectionDetails: state.dataInspectionDetails,
      languageCodes: state.languageCodes,
      companyId: state.companyId,
      ect2Data: {
        ...state.ect2Data,
        isWorkInProgress: isSaved || isTested,
        isDraft: isTested,
      },
      configType: state.configType,
      draft: isTested,
      created: formatDate(new Date(), 'yyyy-MM-ddThh:mm:ss.ssssssZZZZZ', 'en-US'),
      version: state.version,
    }
  }
}
